import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { PopoverController } from '@ionic/angular';
import { FirestoreService } from 'src/app/services/firestore.service';

@Component({
  selector: 'app-checkout-poppup-pago',
  templateUrl: './checkout-poppup-pago.component.html',
  styleUrls: ['./checkout-poppup-pago.component.scss'],
})
export class CheckoutPoppupPagoComponent implements OnInit {
  @Input() user:any ={};
  formasPago =[]
  constructor(
    private popoverController: PopoverController,
    private firestore : FirestoreService
  ) { }

  ngOnInit() {
    this.getFormasPago()
  }


  getFormasPago(){
    this.firestore.getCollection('formasPago/').subscribe(res =>{
      this.formasPago = res
      console.log(this.formasPago);
      
    })
  }





  aceptar(item){
    let path = "users"
    this.firestore.updateDoc( {formaPago: item.name },path,this.user.uid)
    this.popoverController.dismiss()
  }

}
