import { Component, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import * as THREE from 'three';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { FormControl } from '@angular/forms';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';

@Component({
  selector: 'app-select-color',
  templateUrl: './select-color.component.html',
  styleUrls: ['./select-color.component.scss']
})
export class SelectColorComponent implements AfterViewInit {
  @ViewChild('canvasContainer', { static: true }) canvasContainer: ElementRef;
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;

  selectedColor: string;
  model: THREE.Group;
  colorModel = new FormControl('blue');
  color: string = 'blue';
  backgroundFondo = 0x111111;
  texture: THREE.Texture;

  width: number = 1;
  height: number = 1;
  positionX: number = 0;
  positionY: number = 0;
  positionZ: number = 10;


  adjustInterval: any; // Intervalo para mantener pulsado


  colors = [
    { label: 'Rojo', color: 'red' },
    { label: 'Azul', color: 'blue' },
    { label: 'Verde', color: 'green' },
    { label: 'Amarillo', color: '#ffc409' },
    { label: 'Negro', color: '#000000' },
    { label: 'Blanco', color: 'white' },
  ];

  camera: THREE.PerspectiveCamera;
  scene: THREE.Scene;
  stickers: THREE.Mesh[] = []; // Array para mantener el registro de stickers

  constructor() { }

  ngAfterViewInit() {
    this.initThree();
    this.cambiarColor();
  }

  initThree() {
    const scene = new THREE.Scene();
    scene.background = new THREE.Color(0xffffff);

    const directionalLight = new THREE.AmbientLight(0xffffff, 1);
    directionalLight.position.set(0, 3, 0);
    scene.add(directionalLight);

    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer();
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setClearColor(0xffffff, 1);
    this.canvasContainer.nativeElement.appendChild(renderer.domElement);

    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;

    controls.dampingFactor = 0.05;
    controls.rotateSpeed = 1.5;
    controls.minDistance = 100;
    controls.maxDistance = 150;

    const axesHelper = new THREE.AxesHelper(10); // El número 50 es el tamaño de los ejes
    scene.add(axesHelper);


    axesHelper.position.set(-25, 70, 0); // Mueve los ejes a la posición deseada en la escena
    this.addAxisLabels(scene);
    // Cargar una fuente para añadir las letras X, Y, Z

    const mtlLoader = new MTLLoader();
    const objLoader = new OBJLoader();

    mtlLoader.load('https://firebasestorage.googleapis.com/v0/b/aborigen-99669.appspot.com/o/modelos3d%2Fmateriales%2Fcamiseta1.mtl?alt=media&token=f847ee18-ffa1-4df5-90d1-6b80cb8e4647', (materials) => {
      materials.preload();
      objLoader.setMaterials(materials);
      objLoader.load('https://firebasestorage.googleapis.com/v0/b/aborigen-99669.appspot.com/o/modelos3d%2Fobjetos%2Fcamiseta1.obj?alt=media&token=b0c4bdef-9725-421a-a5d4-03076749eee7', (object) => {
        this.model = object;
        this.model.position.y += 5 ;
        scene.add(object);
        this.camera = camera;
        this.scene = scene;

        // Aquí se agrega el sticker por defecto
        //this.addDefaultSticker(scene, camera);
      });
    });

    camera.position.z = 135;

    function animate() {
      requestAnimationFrame(animate);
      renderer.render(scene, camera);
    }

    animate();
  }


 // Ajustar tamaño
 startAdjustSize(delta: number) {
  console.log(delta);
console.log( this.width);

  this.adjustInterval = setInterval(() => {
    this.width += delta;
    this.height += delta;

    if (this.width < 0.1) this.width = 0.1;  // Limitar tamaño mínimo
    if (this.height < 0.1) this.height = 0.1;

    this.changeSize(this.width, this.height);
  }, 100);
}



  stopAdjust() {
    console.log('x');

    clearInterval(this.adjustInterval);
  }

  // Función para ajustar la posición en los ejes (X, Y, Z)
  startAdjustPosition(axis: 'x' | 'y' | 'z', delta: number) {
    this.adjustInterval = setInterval(() => {
      if (axis === 'x') {
        this.positionX += delta;
      } else if (axis === 'y') {
        this.positionY += delta;
      } else if (axis === 'z') {
        this.positionZ += delta;
      }
      this.cambiarPosicionSticker(this.positionX, this.positionY, this.positionZ);
    }, 100);


  }

  // Aquí modificamos la función para cambiar la posición del último sticker





  addSticker(scene: THREE.Scene, camera: THREE.PerspectiveCamera, img: string) {
    const textureLoader = new THREE.TextureLoader();
    textureLoader.load(img, async (texture) => {
      const stickerMaterial = new THREE.MeshBasicMaterial({ map: texture, transparent: true });
      const stickerGeometry = new THREE.PlaneGeometry(15, 15);
      const sticker = new THREE.Mesh(stickerGeometry, stickerMaterial);

      // Calcular la posición del sticker
      const modelPosition = this.model.position.clone();
      const cameraPosition = camera.position.clone();
      const direction = new THREE.Vector3().subVectors(modelPosition, cameraPosition).normalize();

      // Colocar el sticker en un punto entre la cámara y el modelo
      await sticker.position.copy(cameraPosition).add(direction.multiplyScalar(110));
      console.log(cameraPosition);

      sticker.position.y = sticker.position.y +30
       // Actualiza las posiciones con las coordenadas del sticker
    this.positionX = sticker.position.x;
    this.positionY = sticker.position.y;
    this.positionZ = sticker.position.z;
      // Puedes ajustar la distancia aquí
      sticker.lookAt(camera.position);

      scene.add(sticker);
      this.stickers.push(sticker); // Agrega el nuevo sticker al array


    });
  }

  adjustSize(delta: number) {
    this.width += delta;
    this.height += delta;

    if (this.width < 0.1) this.width = 0.1;  // Limitar tamaño mínimo
    if (this.height < 0.1) this.height = 0.1;

    this.changeSize(this.width, this.height);  // Aplicar el nuevo tamaño
  }

  cambiarColor() {
    this.colorModel.valueChanges.subscribe(res => {
      this.color = res;
      const material = (this.model.children[0] as THREE.Mesh).material as THREE.MeshPhongMaterial;
      material.color.set(this.color);
    });
  }

  cambiarPosicionSticker(x: number, y: number, z: number) {
    if (this.stickers.length > 0) { // Verifica si hay stickers
      const lastSticker = this.stickers[this.stickers.length - 1]; // Obtiene el último sticker añadido
      lastSticker.position.set(x, y, z);
      lastSticker.lookAt(this.camera.position);
    } else {
      console.error('No hay stickers en la escena.');
    }
  }

  addStickerFromFile() {
    this.fileInput.nativeElement.click(); // Simula el clic en el input de archivo
  }

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files[0]) {
      const file = input.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        this.addSticker(this.scene, this.camera, e.target.result as string); // Usa la nueva función
      };

      reader.readAsDataURL(file);
    }
  }

  cambiarPosicionEstampado(x: number, y: number) {
    if (this.stickers.length > 0) {
      const lastSticker = this.stickers[this.stickers.length - 1]; // Obtiene el último sticker añadido
      lastSticker.position.set(x, 10, 10);
      lastSticker.lookAt(0, 0, 0);
    }
  }

  changeSize(width: number, height: number) {
    if (this.stickers.length > 0) {
      const lastSticker = this.stickers[this.stickers.length - 1]; // Obtiene el último sticker añadido
      lastSticker.scale.set(width, height, 1);
    } else {
      console.error('No hay stickers en la escena.');
    }
  }

  colorCb(color) {
    this.colorModel.setValue(color);
  }




// Función para agregar las letras X, Y, Z a los ejes
addAxisLabels(scene: THREE.Scene) {
  const loader = new FontLoader();
  loader.load('https://threejs.org/examples/fonts/helvetiker_regular.typeface.json', (font) => {

    const textMaterial = new THREE.MeshBasicMaterial({ color: 0x000000 });

    // Letra X
    const textX = new TextGeometry('X', {
      font: font,
      size: 2,
      height: 0.1,
    });
    const meshX = new THREE.Mesh(textX, textMaterial);
    meshX.position.set( -17, 70, 0); // Posición al final del eje X
    scene.add(meshX);

    // Letra Y
    const textY = new TextGeometry('Y', {
      font: font,
      size: 2,
      height: 0.1,
    });
    const meshY = new THREE.Mesh(textY, textMaterial);
    meshY.position.set(-25, 79, 0) ; // Posición al final del eje Y
    scene.add(meshY);

    // Letra Z
    const textZ = new TextGeometry('Z', {
      font: font,
      size: 2,
      height: 0.1,
    });
    const meshZ = new THREE.Mesh(textZ, textMaterial);
    meshZ.position.set(-25, 70, 10); // Posición al final del eje Z
    scene.add(meshZ);
  });
}

}

