import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {  IonInfiniteScroll, ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { comentario, product } from 'src/app/models/interfaces';
import { AlertsService } from 'src/app/services/alerts.service';
import { AuthService } from 'src/app/services/auth.service';
import { CarritoService } from 'src/app/services/carrito.service';
import { FirestoreService } from 'src/app/services/firestore.service';
import { Location } from '@angular/common'

// import Swiper core and required modules
import { SwiperComponent } from "swiper/angular";

// import Swiper core and required modules
import SwiperCore, { FreeMode, Navigation, Swiper, Thumbs } from "swiper";
import { ImgDetailComponent } from '../img-detail/img-detail.component';
import { ModalVideoComponent } from 'src/app/components/modal-video/modal-video.component';

// install Swiper modules
SwiperCore.use([FreeMode, Navigation, Thumbs]);


@Component({
  selector: 'app-detail-product',
  templateUrl: './detail-product.component.html',
  styleUrls: ['./detail-product.component.scss'],
  encapsulation : ViewEncapsulation.None
})
export class DetailProductComponent implements OnInit, OnDestroy {
  @ViewChild(IonInfiniteScroll) infiniteScroll : IonInfiniteScroll
  url : string;
  thumbsSwiper: any;
  productId
  color
  textButton = "Agregar otra talla"
  product: any;
  productSubscription : Subscription
  comentSubscription : Subscription
  path
  coments: comentario[]=[];
  disablescroll: boolean = false;

  cantidadTalla: any =[];
  interval: NodeJS.Timeout;
  constructor(
    public carrito: CarritoService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private firestore : FirestoreService,
    private auth : AuthService,
    private router : Router,
    private alertsSv : AlertsService,
    private location: Location,
    private modalCtl : ModalController,
    private modalVideo : ModalController,
    ) {
  }
  formComent = this.fb.group({
    coment : ['', Validators.required]
  });

  formCantidad = this.fb.group({
    cant : this.fb.array([
    ])
  })

  get cant() {
    return this.formCantidad.controls["cant"] as FormArray;
  }



  addForm() {
    if (this.product.colors && !this.url){
      this.modalVideoPresent()
      //this.alertsSv.presentAlert('Selecciona el color')
    }else{
      const cantForm = this.fb.group({
          talla: [null, Validators.required],
          cantidad: [1, Validators.required],
          color : []
      });
      this.cant.push(cantForm);
      this.url = undefined

    }
  }

  async ngOnInit() {

    this.product = {name : ''}
    let productId = await this.route.snapshot.params["id"];
    await this.getProduct(productId)
    this.addForm()
    this.limit0()


  }

  async comprar(){
    console.log('comprar funcion');
    if (!this.carrito.auth.uid){
      this.alertsSv.presentAlert('Para hacer un pedido debes iniciar sesion')
      this.router.navigate(['/singin'])
      return
    }
    console.log(this.product);

    if (this.product.colors && !this.url){
      //this.alertsSv.presentAlert('Selecciona el color')
      this.modalVideoPresent()
    }else{
    this.cantidadTalla = []
      let array= this.formCantidad.get('cant').value
      array.forEach(element => {
        if (element['talla']) {
          if (this.carrito.auth.uid) {
            this.cantidadTalla.push(element)
            console.log(this.cantidadTalla);

          }else{
            this.alertsSv.presentAlert('Para hacer un pedido debes iniciar sesion')
            this.router.navigate(['/singin'])
          }
        }
      });
        if (this.cantidadTalla.length < 1) {
          this.presentAlert()
        }else{

          console.log('producto', this.product);

           this.carrito.addProduct(this.product, await this.carrito.reduce(this.cantidadTalla))
          this.formCantidad.reset()
          this.cant.controls = []
          this.addForm()
          this.router.navigate(['/carrito'])
        }
      }
    }

    deleteCant(lessonIndex: number) {
      this.cant.removeAt(lessonIndex);
     }


  async presentAlert(){
    this.alertsSv.presentAlert('por favor selecciona la talla del producto')
  }

  back(): void {
    this.location.back()
  }
  async imgDetail(img){
   // this.router.navigate(['/img',img])

   const modal = await this.modalCtl.create({
    component: ImgDetailComponent,
    componentProps:{
      img
    },
    cssClass: 'transparent-modal'
   });
   modal.present()
  }

  async modalVideoPresent(){


    const modal = await this.modalVideo.create({
    component: ModalVideoComponent,
    cssClass: 'transparent-modal'
   });
   modal.present()
  }

  setSwiperInstance(swiper:Swiper) {
    this.interval = setInterval(() => {
      swiper.slideNext();
    }, 2500);
  }
  getProduct(id){
    let path = 'productos/'
    this.productSubscription = this.firestore.getDoc(path, id).subscribe((producto) => {
      this.product = producto;
      console.log('producto', this.product);

      if (this.product.sizes.length == 1 && this.product.colors) {
        this.textButton = "Agregar otro color"
      }
      this.path =`productos/${this.product.id}/comentarios/`
      this.loadComents()

    });
  }



  add(i){
    let array = this.formCantidad.get('cant').value
    this.formCantidad.get('cant').value[i]['cantidad'] ++
    array[i]['cantidad'] = this.formCantidad.get('cant').value[i]['cantidad']
    this.formCantidad.get('cant').patchValue(array);
  }
  subtraction(i){
    let array = this.formCantidad.get('cant').value
    this.formCantidad.get('cant').value[i]['cantidad'] --
    array[i]['cantidad'] = this.formCantidad.get('cant').value[i]['cantidad']
    this.formCantidad.get('cant').patchValue(array);
  }

  limit0(){
    this.formCantidad.get('cant').valueChanges.subscribe(res =>{
      let array = this.formCantidad.value.cant
      for (let i = 0; i < res.length; i++) {
        const element = res[i];
        if (element['cantidad'] < 1 ) {
          array[i]['cantidad'] = this.formCantidad.get('cant').value[i]['cantidad'] = 1
          this.formCantidad.get('cant').patchValue(array);
        }
      }
    })
  }



  loadComents(){
    let startAt = null
    if (this.coments.length) {
      startAt = this.coments[this.coments.length - 1].fecha
    }
    this.comentSubscription = this.firestore.getCollectionPage(this.path, 3, startAt).subscribe(res =>{
      if (res.length > 0) {
      res.forEach(coment =>{
          if (!this.coments.find(exist => {exist['id'] == coment.id})) {
            this.coments.push(coment)
          }
        })
        this.infiniteScroll.complete()
      }else{
        this.infiniteScroll.complete()
        this.disablescroll = true
      }

    } )
  }

  async coment(){

    if (this.auth.user) {
      if(this.formComent.get('coment').value == '' || !this.formComent.get('coment').value ){
        this.alertsSv.presentAlert('El campo de comentario no puede estar vacio')
      }else{
      const comentario : comentario={
      autor : this.auth.user['name'],
      fecha : new Date(),
      comentario : this.formComent.get('coment').value,
      id : this.firestore.createId()
    }
    await this.firestore.createDoc(comentario, this.path, comentario.id )
    this.coments.unshift(comentario)
    this.formComent.reset()
    }
    }else{
      this.router.navigate(['/singin'])
    }

  }


  ngOnDestroy(): void {
    this.url = undefined
    if (this.productSubscription) {
       this.productSubscription.unsubscribe()
    }
    if (this.comentSubscription) {
      this.comentSubscription.unsubscribe()
    }
    if (this.interval) {
      clearInterval(this.interval)
    }
  }


  slectColor(img){

    if (this.product.colors) {
      this.url = img.url
      this.formCantidad.get('cant').value[this.formCantidad.value.cant.length -1]['color'] = this.url
      this.formCantidad.get('cant').patchValue(this.formCantidad.value.cant);
      if (!this.url) {
        this.modalVideoPresent()
        //this.alertsSv.presentAlert('Selecciona el color')
      }
    }
  }




}
