import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { CarritoService } from 'src/app/services/carrito.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() componentName : string
  subscriptionCart: Subscription;
  cantidadProd:number = 0 ;
  constructor(private menu : MenuController, public carrito : CarritoService,) { }

  ngOnInit() {
    this.getCarrito()
  }

  openMenu(){
    this.menu.toggle('principal')
  }

  getCarrito(){
    this.subscriptionCart = this.carrito.getCart().subscribe(res =>{
      if (res['productos']) {
        this.cantidadProd = res['productos'].length
      }
    })
  }
  ngOnDestroy(): void {
    this.subscriptionCart.unsubscribe()
    
  }

}
