import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PreloadImgService {

  constructor() { }

  defaultImg = "../assets/icons/preloadLogo.svg"
}
