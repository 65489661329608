import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  load: any;

  constructor( private loading : LoadingController) { }
  async showLoading(mensaje) {
    this.load = await this.loading.create({
      message: mensaje
    });
    this.load.present();
  }
}
