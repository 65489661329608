import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SetProductosComponent } from './set-productos/set-productos.component';
import { IonicModule } from '@ionic/angular';
import { ComponentsModule } from '../components/components.module';
import { ReactiveFormsModule } from '@angular/forms';
import { EditProductsComponent } from './edit-products/edit-products.component';
import { PipesModule } from '../pipes/pipes.module';



@NgModule({
  declarations: [
    SetProductosComponent,
    EditProductsComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    ComponentsModule,
    ReactiveFormsModule,
    PipesModule
  ]
})
export class BackendModule { }
