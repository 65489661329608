import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { SetProductosComponent } from './backend/set-productos/set-productos.component';
import { GoogleMapsComponent } from './google-maps/google-maps.component';
import { RutesGuard } from './guards/rutes.guard';
import { AllpedidosComponent } from './pages/allpedidos/allpedidos.component';
import { SignInComponent } from './pages/auth/sign-in/sign-in.component';
import { SignUpComponent } from './pages/auth/sign-up/sign-up.component';
import { CarritoComponent } from './pages/carrito/carrito.component';
import { DetailProductComponent } from './pages/detail-product/detail-product.component';
import { EstiloComponent } from './pages/filters/estilo/estilo.component';
import { GeneroComponent } from './pages/filters/genero/genero.component';
import { HomeComponent } from './pages/home/home.component';
import { ImgDetailComponent } from './pages/img-detail/img-detail.component';
import { PedidosComponent } from './pages/pedidos/pedidos.component';
import { PerfilComponent } from './pages/perfil/perfil.component';
import { TycComponent } from './pages/tyc/tyc.component';
import { DesignGarmentComponent } from './pages/design-garment/design-garment.component';
import { SelectColorComponent } from './pages/select-color/select-color.component';
import { Camiseta3dComponent } from './pages/camiseta3d/camiseta3d.component';
import { HomedesktoppageComponent } from './pages/homedesktoppage/homedesktoppage.component';



const routes: Routes = [
  {path: 'set-productos', component: SetProductosComponent, canActivate:[RutesGuard] },
  {path: 'selectGenero',component:GeneroComponent },
  {path: 'home',component:HomedesktoppageComponent },
  {path: 'perfil',component:PerfilComponent },
  {path: 'singup',component:SignUpComponent },
  {path: 'singin',component:SignInComponent },
  {path: 'carrito',component:CarritoComponent },
  {path: 'pedidos',component:PedidosComponent, canActivate:[RutesGuard] },
  {path: 'tyc',component:TycComponent },
  {path: 'allpedidos',component:AllpedidosComponent },
  {path: 'detailproduct/:id',component:DetailProductComponent },
  {path: 'desingGarment/:genero', component : DesignGarmentComponent},
  {path: 'selectColor/stock/:genero', component : Camiseta3dComponent},
  {path: 'camiseta', component : Camiseta3dComponent},
  {path: 'img/:url',component:ImgDetailComponent },
  {path: 'map',component:GoogleMapsComponent },
  {path: 'estilo/:genero', component: EstiloComponent},
  {path: 'search/:genero/:estilo', component: HomeComponent},
  {path: '',redirectTo: 'home', pathMatch: 'full'},
  {path: '**', redirectTo: 'home', pathMatch: 'full'},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
function canActivate(): import("@angular/router").Route {
  throw new Error('Function not implemented.');
}

