import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { UserInfo } from 'os';
import { EditProfileComponent } from 'src/app/components/edit-profile/edit-profile.component';
import { client } from 'src/app/models/interfaces';
import { AuthService } from 'src/app/services/auth.service';
import { FirestoreService } from 'src/app/services/firestore.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss'],
})
export class PerfilComponent implements OnInit {
  user: any
  path: string;
  ubication: string;

  constructor(public auth : AuthService, 
    private firestore : FirestoreService,
    private modal : ModalController,
    private http: HttpClient,
    private router: Router) { 
    this.user ={}
 
  }

  ngOnInit() {
    this.getPerfil()
  }

  goPedidos(){
    this.router.navigate(['/pedidos'])
  }

  getAddress(){
    const lat = this.user['ubication']['lat'];
    const long = this.user['ubication']['lng'];
    const TU_LLAVE = environment.apikeyGoogle;
    this.http.get<{status: string, results: any[]}>(
         `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=${TU_LLAVE}`,
          {responseType: 'json'}
        ).subscribe(e => {
          if (e.status === 'OK') {
            this.ubication = e.results[0].formatted_address
          }
        });
  }

  async edit(){
    const modal = await  this.modal.create({
      component: EditProfileComponent,
      mode: 'ios',
      swipeToClose : true, 
      componentProps: { 
        user: this.user
      }
    })
    await modal.present()

    const data = await modal.onDidDismiss()
  }

  getPerfil(){
    this.auth.stateAutho().subscribe((res)=>{
      if (res) {
         this.auth.uid = res['uid']
         this.path = `users/`
         this.firestore.getDoc(this.path, this.auth.uid).subscribe((res)=>{
            if (res) {
              this.user = res
              this.getAddress()
              if (this.user['foto'] == '' ||this.user['foto'] == undefined || this.user['foto'] == null) {
                this.user['foto'] = 'https://firebasestorage.googleapis.com/v0/b/aborigen-99669.appspot.com/o/usersImg%2Fuser.png?alt=media&token=cf676f8f-c438-447e-a140-a544f30425e8'
              } 
            }
        })
      } 
    })
  }
 
 
}
