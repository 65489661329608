import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { AlertController, LoadingController, PopoverController, ToastController } from '@ionic/angular';
import { product } from 'src/app/models/interfaces';
import { FirestoreService } from 'src/app/services/firestore.service';
import { StorageService } from 'src/app/services/storage.service';
import { EditProductsComponent } from '../edit-products/edit-products.component';

@Component({
  selector: 'app-set-productos',
  templateUrl: './set-productos.component.html',
  styleUrls: ['./set-productos.component.scss'],
})
export class SetProductosComponent implements OnInit {
  path = 'productos/'
  productos : product[]
  buscar : string = ''
  load : any
  newImage: string ='';
  producto: product;

  
  constructor(
    private firestore : FirestoreService,
    private toast : ToastController,
    private alertController: AlertController,
    public popoverController: PopoverController
    ) { }
    


  ngOnInit() {
   this.getAllProducts()
  }




  getAllProducts(){
   // this.showLoading()
    this.firestore.getCollection<product>(this.path).subscribe(res =>{
      this.productos = res
       // this.load.dismiss()
    }),(err)=>{
     console.log(err);
     this.load.dismiss()
    }
  }

  async deleteProduct(product:product){
    const alert = await this.alertController.create({
      header: 'Deseas eliminar el producto '+product.name ,
      cssClass: 'custom-alert',
      buttons: [
        {
          text: 'No',
          cssClass: 'alert-button-cancel',
          handler:(blah)=>{
          }
        },
        {
          text: 'Si',
          cssClass: 'alert-button-confirm',
          handler:()=>{
            this.firestore.deleteDoc(this.path, product.id).then(()=>
              this.presentToast('Producto eliminado')
            ).catch(()=>
              this.presentToast('El producto no puede ser eliminado intenta mas tarde')
            )
          }
        }
      ]
    });
    await alert.present();
  }
  editProduct(product : product){   
    this.producto = product
    this.presentPopover()
  }

  onSearchChange(e){
    this.buscar = e.detail.value
  }

  async presentToast(mensaje:string) {
    const toast = await this.toast.create({
      message: mensaje,
      duration: 500,
      color: 'light'
    });
    toast.present();
  }

  async presentPopover() {
    const popover = await this.popoverController.create({
      component: EditProductsComponent,
      componentProps: {
        producto: this.producto
      }
    });
    await popover.present();

    popover.onDidDismiss().then(()=>{
      this.producto =undefined
    })

  }

  loadData(e){
    
  //  console.log(e);
  //  setTimeout(() => {
    
  //   const nuevoArr = Array(20);
  //   this.productos.push(...nuevoArr)
  //   e.target.complete()
  //  }, 1000);
  }
}
