import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { estadoPedido, pedido } from 'src/app/models/interfaces';
import { AuthService } from 'src/app/services/auth.service';
import { FirestoreService } from 'src/app/services/firestore.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-allpedidos',
  templateUrl: './allpedidos.component.html',
  styleUrls: ['./allpedidos.component.scss'],
})
export class AllpedidosComponent implements OnInit {
  nuevosSuscriber : Subscription
  path : string = 'pedidos'
  pedidos :any[]=[]
  pedidosEntregados :any[]=[]
  nuevos = true 

  estados : estadoPedido[]=['enviado', 'Visto', 'En Camino', 'entregado']

  TU_LLAVE = environment.apikeyGoogle;
  constructor(private firestore : FirestoreService,
    public auth : AuthService,
    private http: HttpClient,) { }

  ngOnInit() {
    this.getNuevos()
  }
  segmentChanged(ev: any) {
    let opc = ev.detail.value 
    if (opc == 'nuevos') {
      this.nuevos = true 
      this.pedidos = []     
      this.getNuevos()
    }
    if (opc == 'entregados') {
      this.pedidosEntregados = []
      this.nuevos = false
      this.getTerminados()
    }
  }

  getNuevos(){
     
    let startAt = null
    let enviado= []
    if (this.pedidos.length) {
      startAt = this.pedidos[this.pedidos.length - 1].fecha
    }
    this.nuevosSuscriber = this.firestore.getCollectionAll(this.path, 'estado', "==", "enviado", startAt).subscribe((res)=>{
      if (res) {
        res.forEach(pedido =>{
          console.log(res);
          
          if ( pedido.cliente.ubication) {
            this.http.get<{status: string, results: any[]}>(
              `https://maps.googleapis.com/maps/api/geocode/json?latlng=${ pedido.cliente.ubication.lat},${ pedido.cliente.ubication.lng}&key=${this.TU_LLAVE}`,
               {responseType: 'json'}
             ).subscribe(e => {
               if (e.status === 'OK') {
               pedido.cliente.ubication = e.results[0].formatted_address
               }
             });  }
            this.pedidos.push(pedido)
        })

        var hash = {};
        this.pedidos = this.pedidos.filter(function(current) {
          var exists = !hash[current.id];
          hash[current.id] = true;
          return exists;
        });
      }
     
    })
  }
  getTerminados(){
    let startAt = null
    if (this.pedidosEntregados.length) {
      startAt = this.pedidosEntregados[this.pedidosEntregados.length - 1].fecha
    }
    this.nuevosSuscriber = this.firestore.getCollectionAll(this.path, 'estado', "==", "entregado", startAt).subscribe((res)=>{
      if (res) {
        res.forEach(pedido =>{
          if ( pedido.cliente.ubication) {
            this.http.get<{status: string, results: any[]}>(
              `https://maps.googleapis.com/maps/api/geocode/json?latlng=${ pedido.cliente.ubication.lat},${ pedido.cliente.ubication.lng}&key=${this.TU_LLAVE}`,
               {responseType: 'json'}
             ).subscribe(e => {
               if (e.status === 'OK') {
               pedido.cliente.ubication = e.results[0].formatted_address
               }
             });  }
         
            this.pedidosEntregados.push(pedido)
       
        })
        var hash = {};
        this.pedidosEntregados = this.pedidosEntregados.filter(function(current) {
          var exists = !hash[current.id];
          hash[current.id] = true;
          return exists;
        });
   
      }
     
    })
  }

  cambiarEstado(pedido:pedido, estado : any){
    const path =`/users/${pedido.cliente.uid}/pedidos/`
    console.log(path);
    
    const updateDoc = {
      estado : estado.detail.value
    };
    const id = pedido.id
    this.firestore.updateDoc(updateDoc,path,id).then(()=>{
      console.log('Actualizado');
      
    })
  }
 
  loadmost(){
   this.nuevos ?  this.getNuevos() : this.getTerminados()
  }

  ngOnDestroy(): void {
    if (this.nuevosSuscriber) {
       this.nuevosSuscriber.unsubscribe()
    }
  }
}
