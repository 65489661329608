import { Component, Input, OnInit } from '@angular/core';
import { productPedido } from 'src/app/models/interfaces';
import { CarritoService } from 'src/app/services/carrito.service';
import { PreloadImgService } from 'src/app/services/preload-img.service';

@Component({
  selector: 'app-item-carrito',
  templateUrl: './item-carrito.component.html',
  styleUrls: ['./item-carrito.component.scss'],
})
export class ItemCarritoComponent implements OnInit {
  @Input() productoPedido : productPedido
  imgDefault = this.preloadImg.defaultImg
  constructor(
    public carrito: CarritoService,
    private preloadImg : PreloadImgService) { }
  totalProducto:number = 0

  async ngOnInit() {
    let reduce = await this.productoPedido['cantidad'].reduce((acumulador, actual) => acumulador + actual['cantidad'], 0);
    this.totalProducto = this.productoPedido['producto']['price'] * reduce
    
  }
  

  addProduct(i){
    this.productoPedido.cantidad[i]['cantidad'] ++
    this.carrito.addProduct(this.productoPedido.producto, this.productoPedido.cantidad, true)
  }


  async removeProduct(i){
      let quitar = false 
      this.productoPedido.cantidad[i]['cantidad'] --
      if ( this.productoPedido.cantidad[i]['cantidad'] < 1) {
       await this.productoPedido.cantidad.splice(i, 1)
       if (this.productoPedido.cantidad.length == 0) {
        quitar = true
       }
      }
      this.carrito.removeProduct(this.productoPedido.producto, this.productoPedido.cantidad, quitar)
  }
  

}
