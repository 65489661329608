import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { resolve } from 'dns';
import { Observable, Subject } from 'rxjs';
import { pedido, product } from '../models/interfaces';
import { AuthService } from './auth.service';
import { FirestoreService } from './firestore.service';

@Injectable({
  providedIn: 'root'
})
export class CarritoService {

  public pedido
  pedido$  = new Subject <any>()
  path = `users/${this.auth.uid}/carrito/`
  constructor(
    private firestore : FirestoreService,
    public auth : AuthService,
    public router : Router ) {
    this.initCart()
    this.loadCart()
  }

  async loadCart(){


    this.auth.stateAutho().subscribe((res)=>{
      if (res) {
         this.auth.uid = res['uid']
         this.path = `users/${this.auth.uid}/carrito/`
         this.firestore.getDoc(this.path, this.auth.uid).subscribe((res)=>{
            if (res) {
              this.pedido = res
              this.pedido$.next(this.pedido)
            }else{
              this.initCart()
            }
        })
      }
    })
  }

  getCart():Observable<any>{
    setTimeout(() => {
      this.pedido$.next(this.pedido)
    }, 100);
   return this.pedido$.asObservable()
  }

  initCart(){
    this.pedido = {
      id : this.auth.uid,
      cliente : this.auth.user,
      productos : [],
      precioTotal : null,
      estado : 'enviado',
      fecha : new Date(),
      valoracion : null
    }
    this.pedido$.next(this.pedido)
  }
  reduce( array : any){
   let arra = []
    arra = array.reduce((acumulador, valorActual) => {
      const elementoYaExiste = acumulador.find(elemento => elemento.talla === valorActual['talla']);
      const colorExiste = acumulador.find(elemento => elemento.color === valorActual['color']);
      if (elementoYaExiste && colorExiste) {
        return acumulador.map((elemento) => {
          if (elemento.talla === valorActual['talla']  ) {
            return {
              ...elemento,
              cantidad: elemento.cantidad + valorActual['cantidad']
            }
          }

          return elemento;
        });
      }

      return [...acumulador, valorActual];
    }, []);


    return arra
  }


  addProduct(producto : any, cantidad: any, addcar?){

    if(this.auth.uid){
      const item = this.pedido.productos.find(productoPedido =>{
        return productoPedido['producto']['id'] === producto['id']
      })
      if (item) {
       addcar ? item.cantidad = cantidad : item.cantidad = this.reduce(item.cantidad.concat(cantidad))
      }else{
        const add ={
          cantidad : cantidad,
          producto
        }
        this.pedido.productos.push(add)
      }
    }else{
      this.router.navigate(['/singin'])
      return
    }
    this.firestore.createDoc(this.pedido, this.path,this.auth.uid )
  }

  removeProduct(producto : product, cantidad, quitar ?){
    if(this.auth.uid){
      let position = 0
      const item = this.pedido.productos.find((productoPedido, index) =>{
        position = index
        return productoPedido['producto']['id'] === producto['id']
      })
      if (item) {
        item['cantidad'] = cantidad;
         if (quitar) {
          this.pedido.productos.splice(position, 1)
        }
      }
    }
    this.firestore.createDoc(this.pedido, this.path,this.auth.uid,  )
  }

  startShop(){
  }

  async clearCart(){
    await this.firestore.deleteDoc(this.path, this.auth.uid)
    this.initCart()
  }
}
