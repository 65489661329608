import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FirestoreService } from 'src/app/services/firestore.service';

@Component({
  selector: 'app-slide-categorias',
  templateUrl: './slide-categorias.component.html',
  styleUrls: ['./slide-categorias.component.scss'],
})
export class SlideCategoriasComponent implements OnInit {
  categorias: any =[];
  @Input() namecategoty : string
  @Input() gender: string
  @Output() category = new EventEmitter()
  allcategorias: any =[];

  constructor( private firestore : FirestoreService) { }

  ngOnInit() {
    this.getCategorias()
  }


  getCategorias(){
    console.log('filtro categoria');
    
    let path = 'categorias/'
    this.firestore.getCollection(path).subscribe(res =>{
      this.allcategorias = res
    
      
      this.allcategorias.sort(function (a, b) {
        if (a.posicion > b.posicion) {
          return 1;
        }
        if (a.posicion < b.posicion) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });

      this.watchCategory()
      
    })
   
  }


  watchCategory(){
    
    if (this.allcategorias) {
      
       this.allcategorias.forEach(element => {
        if (element.hasOwnProperty(this.gender)) {
          this.categorias.push(element)
        }
       });
    }
  
  }

  changeCategory(categoria){
    
    console.log(categoria);
    
    this.category.emit(categoria)
  }
}
