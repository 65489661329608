import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

  urlInstagram = 'https://www.instagram.com/aborigen.styles/'
  urlFacebook = 'https://www.facebook.com/profile.php?id=100085221776750'
  whatsapp ='https://wa.me/+573163774835'
  goToLink(url){
    window.open(url, "_blank")
  }
}
