import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { GoogleMapsComponent } from 'src/app/google-maps/google-maps.component';
import { CommonService } from 'src/app/services/common.service';
import { FirestoreService } from 'src/app/services/firestore.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss'],
})
export class EditProfileComponent implements OnInit {
  newImage: string;
  user : any
  newfile: any;
  constructor(
    public modal : ModalController,
    public modalcontrol : ModalController,
    private fb: FormBuilder,  
    private firestore : FirestoreService,
    private common : CommonService,
    private storage : StorageService,
  ) { }


  formClient = this.fb.group({
    uid:[''],
    name: ['', Validators.required],
    foto:[''],
    photo : [''],
    phone : ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
    ubication : [null],
    ubicationDescription:'',
    email : ['', [Validators.required] ],
    
  });
  path = 'users/'

  private emailPattern: any = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  get name(){return this.formClient.get('name') }
  get phone() {return this.formClient.get('phone') }
  get email() {return this.formClient.get('email') }

  ngOnInit() {
    this.setForm()
    console.log(this.user.ubication);
    
  }

  setForm(){
    this.formClient.get('name').setValue(this.user['name'])
    this.formClient.get('phone').setValue(this.user['phone'])
    this.formClient.get('photo').setValue(this.user['photo'])
    this.formClient.get('ubication').setValue(this.user['ubication'])
    this.formClient.get('ubicationDescription').setValue(this.user['ubicationDescription'])
    this.formClient.get('uid').setValue(this.user['uid'])
    this.formClient.get('email').setValue(this.user['email'])
  }



  back(){
    this.modal.dismiss()
  }


  async newImg(photo:any){
    try {
      if (photo.target.files && photo.target.files[0]) {
        const reader = new FileReader();
        reader.onload = ((img)=>{
          //previsualizar la imagen en front
          this.newImage = img.target.result as string
          //subir la imagen a firestore
          this.newfile = photo.target.files[0]
        });
        reader.readAsDataURL(photo.target.files[0])
      }
      this.newImage = photo.target.files[0]

    } catch (error) {
     console.log('no se pudo cargar');
    }
  }

  async ubication(){ 
    // la ubicacion maps
    const modal = await this.modalcontrol.create({
      component: GoogleMapsComponent,
      mode: 'ios',
      swipeToClose : true,
      componentProps : {ubication : this.user.ubication}
    })
    await modal.present()
    const data = await modal.onDidDismiss()
    this.formClient.get('ubication').setValue(data.data['pos'])
  }

  removeImg(){
    this.newImage = undefined
  }


  async uploadImg(){
    try {
           const path = "usersImg"
           const name = this.firestore.createId() + new Date()
           const file = this.newfile
           const res = await this.storage.uploadImg(file,path,name )
           this.formClient.get('foto').setValue(res)
          
    } catch (error) {
      console.log('error al cargar la imagen', error);
    }
  }
  async edit(){
    await this.common.showLoading('Cargando')
    if (this.formClient.valid) {
        await this.uploadImg()
        this.firestore.updateDoc(this.formClient.value, this.path, this.formClient.get('uid').value).then(()=>{
        this.common.load.dismiss()
        this.modal.dismiss()
       })
    }
  }


}
