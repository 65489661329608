import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { finalize } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(private storage : AngularFireStorage) { }


  uploadImg(file : any , path:string, nameImage:string):Promise<string>{
    return new Promise(resolve=>{
      const filePath = `${path}/${nameImage}`;
      const ref = this.storage.ref(filePath);
      const task = ref.put(file)
      task.snapshotChanges().pipe(
        finalize(()=>{
          ref.getDownloadURL().subscribe(res =>{
            const downloadUrl = res
            resolve(  downloadUrl)
            return
          })
        })
      ).subscribe()
     
    })
  }
}
