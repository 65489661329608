import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { IonicModule } from '@ionic/angular';
import { ComponentsModule } from '../components/components.module';
import { PerfilComponent } from './perfil/perfil.component';
import { SignUpComponent } from './auth/sign-up/sign-up.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SignInComponent } from './auth/sign-in/sign-in.component';
import { CarritoComponent } from './carrito/carrito.component';
import { PedidosComponent } from './pedidos/pedidos.component';
import { AllpedidosComponent } from './allpedidos/allpedidos.component';
import { DetailProductComponent } from './detail-product/detail-product.component';
import { GeneroComponent } from './filters/genero/genero.component';
import { EstiloComponent } from './filters/estilo/estilo.component';
import { SwiperModule } from 'swiper/angular';
import { ImgDetailComponent } from './img-detail/img-detail.component';
import { HttpClientModule } from '@angular/common/http';
import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { CheckoutComponent } from './checkout/checkout.component';
import { TycComponent } from './tyc/tyc.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { DesignGarmentComponent } from './design-garment/design-garment.component';
import { SelectColorComponent } from './select-color/select-color.component';
import { Camiseta3dComponent } from './camiseta3d/camiseta3d.component';
import { HomedesktoppageComponent } from './homedesktoppage/homedesktoppage.component';
registerLocaleData(localeEs, 'es');

@NgModule({
  declarations: [
    HomeComponent,
    PerfilComponent,
    SignUpComponent,
    SignInComponent,
    CarritoComponent,
    PedidosComponent,
    AllpedidosComponent,
    DetailProductComponent,
    GeneroComponent,
    EstiloComponent,
    ImgDetailComponent,
    CheckoutComponent,
    TycComponent,
    DesignGarmentComponent,
    SelectColorComponent,
    Camiseta3dComponent,
    HomedesktoppageComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    ComponentsModule,
    ReactiveFormsModule,
    SwiperModule,
    HttpClientModule,
    LazyLoadImageModule,
    FormsModule
  ],
  providers: [
  {provide: LOCALE_ID, useValue: 'es'}
]
})
export class PagesModule { }
