import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { CarritoService } from 'src/app/services/carrito.service';
import { FirestoreService } from 'src/app/services/firestore.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  log: any;
  authentication : Subscription;
  admin = false
  constructor(
    public auth : AuthService, 
    private firestore : FirestoreService,
    private router : Router,
    private carritoSv : CarritoService
    ) { }

  
  ngOnInit() {
   this.getAuth()
  }

  getAuth(){
    this.auth.stateAutho().subscribe((res)=>{
      if (res) {
         this.auth.uid = res['uid']
          this.getUserInfo(this.auth.uid)
      } else{
        this.auth.user = null
        this.auth.uid = null
      }
    })
  }

  whatsapp(){
    window.open("https://wa.me/3163774835");
  }

  getUserInfo( id){
    const path = 'users'
      this.authentication = this.firestore.getDoc(path, id).subscribe(res=>{
        this.auth.user = res
        localStorage.getItem('auth') == null || localStorage.getItem('auth') == 'true' ?  this.auth.user = res : this.auth.user = null 
        if (this.auth.user) {
          this.auth.user.hasOwnProperty('admin') ? this.admin = true : this.admin = false
        }
    }) 
  }

  async logout(){
    await this.auth.logout()
    this.auth.user == null
    this.auth.uid == null
    setTimeout(() => {
        this.carritoSv.initCart()
    }, 1000);
    this.authentication.unsubscribe()
    this.router.navigate(['/'])
  }


}
