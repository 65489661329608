import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoadingController, ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { GoogleMapsComponent } from 'src/app/google-maps/google-maps.component';
import { client } from 'src/app/models/interfaces';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common.service';
import { FirestoreService } from 'src/app/services/firestore.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit, OnDestroy {
  cliente : client = {
    uid:'',
    name: '',
    photo : '',
    phone : null,
    ubication : null,
    ubicationDescription:'',
    email : '',
    password : '',
    
  }
  passwordSub : Subscription;
  passwordSub2 : Subscription;
  newfile: any;
  newImage:any;
  user: any;
  load: any;
 
  constructor(
    private loading : LoadingController,
    private fb: FormBuilder,  
    private auth : AuthService,
    private storage : StorageService,
    private firestore : FirestoreService,
    private common : CommonService,
    private router : Router,
    private modalcontroler : ModalController
    ) { }

    private emailPattern: any = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    noCoincide = false
    get name(){return this.formClient.get('name') }
    get phone() {return this.formClient.get('phone') }
    get email() {return this.formClient.get('email') }
    get password() {return this.formClientPass.get('password') }
    get password2() {return this.formClientPass.get('password2') }

  formClient = this.fb.group({
    uid:[''],
    name: ['', Validators.required],
    foto:[''],
    photo : [''],
    phone : ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
    ubication : [null],
    ubicationDescription:'',
    email : ['', [Validators.required, Validators.pattern(this.emailPattern)] ],
    
  });

  formClientPass = this.fb.group({
    password : ['', [Validators.required, Validators.minLength(6)]],
    password2 : ['', [Validators.required]]
  })

  async ngOnInit() {
    this.verfyPassword()
  }

  verfyPassword(){
   this.passwordSub =  this.formClientPass.controls['password2'].valueChanges.subscribe(value => {
      if (this.formClientPass.get('password').value != value) {
        this.noCoincide = true
      }else{
        this.noCoincide = false
      }
    });
    this.passwordSub2 =  this.formClientPass.controls['password'].valueChanges.subscribe(value => {
      if (this.formClientPass.get('password2').value != value) {
        this.noCoincide = true
      }else{
        this.noCoincide = false
      }
    });
  }

  async register(){
      const credential ={
        email : this.formClient.get('email').value,
        password : this.formClientPass.get('password').value
      }
      if (this.formClient.valid && this.formClientPass.valid) { 
      const resp = await this.auth.register( credential.email, credential.password)
      this.cliente.uid = await this.auth.getUid()
   
      this.saveDatesUser()
     }else{
      console.log('no valid');
      this.formClient.markAllAsTouched()
      this.formClientPass.markAllAsTouched()
   }
  }

    async saveDatesUser(){
      this.common.showLoading('Cargando')
      const path = "users"
      const name = this.cliente.name;
      this.formClient.get('uid').setValue(this.cliente.uid)
      await this.uploadImg()
      this.firestore.createDoc(this.formClient.value, path, this.cliente.uid ).then(()=>{
        this.newImage = undefined
        this.formClient.reset()
        this.formClientPass.reset()
        this.common.load.dismiss()
        this.router.navigate(['/home'])
      })
     
    }

    async newImg(photo:any){
      try {
        if (photo.target.files && photo.target.files[0]) {
          const reader = new FileReader();
          reader.onload = ((img)=>{
             this.newImage = img.target.result as string
             this.newfile = photo.target.files[0]
          });
          reader.readAsDataURL(photo.target.files[0])
        }
      } catch (error) {
       console.log('no se pudo cargar');
      }
    }


    async uploadImg(){
      try {
             const path = "usersImg"
             const name = this.firestore.createId() + new Date()
             const file = this.newfile
             const res = await this.storage.uploadImg(file,path,name )
             this.formClient.get('foto').setValue(res)
            console.log();
            
      } catch (error) {
        console.log('error al cargar la imagen', error);
      }
    }

    async ubication(){ 
      // la ubicacion maps
      const modal = await this.modalcontroler.create({
        component: GoogleMapsComponent,
        mode: 'ios',
        swipeToClose : true,
       // componentProps : {position}
      })
      await modal.present()

      const data = await modal.onDidDismiss()
      this.formClient.get('ubication').setValue(data.data['pos'])
    }

    removeImg(){
      this.newImage = undefined
    }

    login(){
      this.router.navigate(['/singin'])
    }
    ngOnDestroy(): void {
      this.passwordSub.unsubscribe()
      this.passwordSub2.unsubscribe()
    }

}