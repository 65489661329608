import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-homedesktoppage',
  templateUrl: './homedesktoppage.component.html',
  styleUrls: ['./homedesktoppage.component.scss'],
})
export class HomedesktoppageComponent {

  constructor(private router : Router) { }


  goGenero(){
    this.router.navigate(['/selectGenero'])
  }

}
