import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { GoogleMapsService } from './google-maps.service';
declare var google : any
import { Geolocation } from '@capacitor/geolocation';
import { CommonService } from '../services/common.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';
import { FirestoreService } from '../services/firestore.service';

@Component({
  selector: 'app-google-maps',
  templateUrl: './google-maps.component.html',
  styleUrls: ['./google-maps.component.scss'],
})
export class GoogleMapsComponent implements OnInit {

  position ={
    lat : 4.6544459,
    lng : -74.2151949
  };
  address = ''
  direccion$  = new Subject <any>()
  direccion: string = '';
  ubication 
  label ={
    titulo : 'Ubicacion de entrega',
    subtitulo : 'Ubicacion de envio' + this.address 
  };
  TU_LLAVE = environment.apikeyGoogle;

  map: any;
  marker: any;
  infowindow : any;
  positionSet: any;

  @ViewChild('map') divMap: ElementRef
  @Input() id = '' ;
    title = 'rou';
  //Local Variable defined
  formattedaddress=" ";
  options: Options = new Options({
    componentRestrictions: {country: 'CO'}
  });
  public async AddressChange(address: any) {
  this.formattedaddress=address.formatted_address
   if (address.geometry.viewport) {
    this.map.fitBounds(address.geometry.viewport)
   }else{
    this.map.setCenter(address.geometry.location)
   }
   this.marker.setPosition(address.geometry.location)
   this.marker.setVisible(true)
   this.direccion = address.formatted_address
   this.setInfoWindow(this.marker, this.label.titulo, this.direccion)
      this.positionSet = {
      lat : address.geometry.viewport['Ya']['lo'],
      lng : address.geometry.viewport['Ia']['lo']
      }
   
    
  }


  constructor(private renderer: Renderer2,
    @Inject(DOCUMENT) private document,
    public googleMapsService : GoogleMapsService,
    public modalControler : ModalController,
    private common : CommonService,
    private http: HttpClient,
    private firestore : FirestoreService
    ) { }

    autocomplete

  ngOnInit() {
    this.init()
    this.localitation()
  }
  localitation(){
    if(this.ubication){
      this.position = this.ubication
    }
  }
  async init(){
    await this.common.showLoading('Cargando')
     this.googleMapsService.init(this.renderer, this.document).then(async ()=>{
      navigator.geolocation.getCurrentPosition( (posi)=>{
       this.position = {
        lat : posi.coords.latitude,
        lng : posi.coords.longitude
       }
       this.initMap(this.position);
      } )
      
     }).catch(
      (error)=>{console.log('error', error);
      }
     )  
  }

  async initMap(position){

    this.autocomplete = document.getElementById('search')
    
    // const search = new google.maps.places.autocomplete(this.autocomplete)
    // search.bindTo("bounds", this.map)
   
    let latLng = new google.maps.LatLng(position.lat, position.lng);
    let mapOptions ={
      center : latLng,
      zoom : 18,
      disableDefaultUI:true,
      clickableIcons : false
    };

    this.map = new google.maps.Map(this.divMap.nativeElement, mapOptions);
    this.marker = new google.maps.Marker({
      map : this.map,
      animation : google.maps.Animation.DROP,
      draggable : true,
      title : "Ubicacion de entrega"
    });
    this.clickHandleEvent();
    this.infowindow = new google.maps.InfoWindow()
    this.addMarker(this.position)
    this.getAddress(position).subscribe(res =>{
      this.address = res
       this.setInfoWindow(this.marker, this.label.titulo, this.address)
       this.common.load.dismiss()
    })
  }

  clickHandleEvent(){
    this.map.addListener('click', (event : any)=>{
      this.document.getElementById('search').value = ''
      const position ={
        lat: event.latLng.lat(),
        lng : event.latLng.lng()
      }
      this.addMarker(position)
    })
  }

  addMarker(position:any){
    let latLng = new google.maps.LatLng( position['lat'], position['lng']);
    this.marker.setPosition(latLng);
    this.map.panTo(position)
    this.positionSet = position
    this.http.get<{status: string, results: any[]}>(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.lat},${position.lng}&key=${this.TU_LLAVE}&libraries=places&language=es`,
       {responseType: 'json'}
     ).subscribe(e => {
       if (e.status === 'OK') {
         this.direccion = e.results[0].formatted_address
         this.direccion$.next(this.direccion)
         return this.direccion$.asObservable()
         
       }
     });
    
    
    this.setInfoWindow(this.marker, this.label.titulo, this.direccion)
  }

  setInfoWindow(marker, titulo, subtitulo){
    const contentString ='<div id="contentInsideMap">'+
                         '<div>'+
                         '</div>'+
                         '<p style="font-weight:bold; margin-bottom:5px; color:black;">'+titulo+'</p>'+
                         '<div id="bodyContent">'+
                         '<p class="normal m-0" style="color:black;">'+
                         subtitulo +'</p>'+
                         '</p>'+
                         '</p>';
      this.infowindow.setContent(contentString)
      this.infowindow.open(this.map, marker)
  }

  aceptar(){
    let path = "users"
    this.firestore.updateDoc( {ubication: this.positionSet},path,this.id)
    console.log(this.positionSet);
    this.modalControler.dismiss({pos:this.positionSet})
  }
  async mylocation(){
    await this.common.showLoading('Cargando')
    Geolocation.getCurrentPosition().then((res)=>{
      
      const position ={
        lat : res.coords.latitude,
        lng : res.coords.longitude
      }
      this.addMarker(position)
      this.common.load.dismiss()
    })

    
  }


  getAddress(position):Observable<any>{
    
    const lat = this.position.lat
    const long = this.position.lng

    this.http.get<{status: string, results: any[]}>(
         `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.lat},${position.lng}&key=${this.TU_LLAVE}&libraries=places&language=es`,
          {responseType: 'json'}
        ).subscribe(e => {
          if (e.status === 'OK') {
            this.direccion = e.results[0].formatted_address
            this.direccion$.next(this.direccion)
            return this.direccion$.asObservable()
            
          }
        });

        return this.direccion$.asObservable()
  }



  
}
