import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { HeaderComponent } from './header/header.component';
import { MenuComponent } from './menu/menu.component';
import { AppRoutingModule } from '../app-routing.module';
import { ProductoComponent } from './producto/producto.component';
import { ItemCarritoComponent } from './item-carrito/item-carrito.component';
import { ItemPedidosComponent } from './item-pedidos/item-pedidos.component';
import { SlidePromocionesComponent } from './slide-promociones/slide-promociones.component';
import { SlideCategoriasComponent } from './slide-categorias/slide-categorias.component';
import { FooterComponent } from './footer/footer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { CheckoutPoppupComponent } from './popover/checkout-poppup/checkout-poppup.component';
import { CheckoutPoppupPagoComponent } from './popover/checkout-poppup-pago/checkout-poppup-pago.component';
import { CheckoutPoppupDireccionComponent } from './popover/checkout-poppup-direccion/checkout-poppup-direccion.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { ModalVideoComponent } from './modal-video/modal-video.component';


@NgModule({
  declarations: [
    HeaderComponent,
    MenuComponent,
    ProductoComponent,
    ItemCarritoComponent,
    ItemPedidosComponent, 
    SlidePromocionesComponent,
    SlideCategoriasComponent,
    FooterComponent,
    EditProfileComponent,
    CheckoutPoppupComponent,
    CheckoutPoppupPagoComponent,
    CheckoutPoppupDireccionComponent,
    ModalVideoComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    LazyLoadImageModule
  ],
  exports :[
    HeaderComponent,
    MenuComponent,
    ProductoComponent,
    ItemCarritoComponent,
    ItemPedidosComponent,
    SlidePromocionesComponent,
    SlideCategoriasComponent,
    FooterComponent,
    CheckoutPoppupComponent,
    CheckoutPoppupPagoComponent,
    CheckoutPoppupDireccionComponent,
    ModalVideoComponent
  ]
})
export class ComponentsModule { }
