import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import SwiperCore, {SwiperOptions, Zoom} from 'swiper'
SwiperCore.use([Zoom])
@Component({
  selector: 'app-img-detail',
  templateUrl: './img-detail.component.html',
  styleUrls: ['./img-detail.component.scss'],
})
export class ImgDetailComponent implements OnInit {
  
 // img: string;
  @Input()img:string
  constructor( 
    private modalCrl : ModalController
   ) { }

     config : SwiperOptions ={
      zoom: true
     }
  async ngOnInit() {
   
  }

  back(): void {
    this.modalCrl.dismiss()
  }

}
