import { Injectable } from '@angular/core';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { CapacitorConfig } from '@capacitor/cli';
import { Platform } from '@ionic/angular';
import { Plugins } from '@capacitor/core';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { FirestoreService } from './firestore.service';
const { LocalNotifications } = Plugins;
const config: CapacitorConfig = {
  plugins: {
    LocalNotifications: {
      smallIcon: "ic_stat_icon_config_sample",
      iconColor: "#488AFF",
      sound: "beep.wav",
    },
  },
};


@Injectable({
  providedIn: 'root'
})
export class NotificationsServiceService {


  constructor(
    public platform : Platform,
    public router : Router,
    private auth : AuthService,
    private firestore : FirestoreService
  ) {
    this.stateUser()
   }


  init(){
    if (this.platform.is('capacitor')) {
      console.log('si es celular');
      PushNotifications.requestPermissions().then(result => {
        if (result.receive === 'granted') {
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register();
          this.addlistener()
        } else {
          // Show some error
        }
      });
      
    }else{
      console.log('no es celular');
      
    }
  }

  addlistener(){
      // On success, we should be able to receive notifications
      
      PushNotifications.addListener('registration',
      (token: Token) => {
        if (localStorage.getItem('token')) {
          this.guardarToken(token.value)
        }else{
          localStorage.setItem('token', token.value.toString() )
          alert('Recibir notificaciones de la app?');
          this.guardarToken(token.value)
        }
        
        //guardar token
      }
    );

    }


    stateUser(){
      this.auth.stateAutho().subscribe(res =>{
        if(res){
          this.init()
        }
      })
    }


    async guardarToken(token : any){
      const Uid = await this.auth.getUid();
      if (Uid) {
        const path = '/users'
        const userUpdate ={
          token : token
        }
        this.firestore.updateDoc(userUpdate, path, Uid);
      }

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
      (error: any) => {
        alert('Error on registration: ' + JSON.stringify(error));
      }
    );

    //primer plano
    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        LocalNotifications.schedule({
          id: 1, 
          text: 'Single ILocalNotification',
          title : notification.title,
        });
        this.router.navigate([notification.data.enlace])
        
      }
    );

    //click notificacion
    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        
        this.router.navigate([notification.notification.data.enlace])
      }
    );

    //click notificacion local
    LocalNotifications.addListener('localNotificationActionPerformed'),
    (notification : ActionPerformed)=> {
      this.router.navigate([notification.notification['extra']['data']['enlace']])
  }
  }}
