    import { Component, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
    import * as THREE from 'three';
    import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
    import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader';
    import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
    import { fabric } from 'fabric';
    import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { CarritoService } from 'src/app/services/carrito.service';

    @Component({
      selector: 'app-camiseta3d',
      templateUrl: './camiseta3d.component.html',
      styleUrls: ['./camiseta3d.component.scss'],
    })
    export class Camiseta3dComponent implements AfterViewInit {

      @ViewChild('threejsContainer', { static: true }) threejsContainer!: ElementRef;
      @ViewChild('previewCanvas', { static: true }) previewCanvas!: ElementRef;
      private canvas!: fabric.Canvas;  // Canvas de Fabric.js para la edición de la imagen

      selectedColor: string;
    colorModel = new FormControl('withe'); // Control del color seleccionado
    color: string = 'withe'; // Color inicial del modelo
      scene!: THREE.Scene;
      camera!: THREE.PerspectiveCamera;
      renderer!: THREE.WebGLRenderer;
      model!: THREE.Object3D | null;
      controls!: OrbitControls;
      selectedImage: fabric.Image | null = null;

      constructor(private router : Router, private carrito: CarritoService) {}

      ngAfterViewInit() {
        this.initThree();
        this.cambiarColor('white');
        this.initFabric('white');

      }

      cambiarColor(color:string) {
        if (this.canvas) {
          this.color = color
          this.canvas.setBackgroundColor(color, this.canvas.renderAll.bind(this.canvas));
        }
        this.color = color; // Actualiza el color basado en la selección
        if (this.model) {
          this.model.traverse((child: any) => {
            if (child.isMesh) {
              const material = child.material as THREE.MeshPhongMaterial;

              // Si existe una textura (map), mantenerla y cambiar solo el color base
              if (material.map) {
                material.color.set(color); // Cambiar el color base del material
                material.needsUpdate = true;
              } else {
                // Si no existe textura, solo aplicar el color
                material.color.set(color);
                material.needsUpdate = true;
              }
            }
          });
        }


        this.updateTextureInRealTime()
      }


      // Inicializa Three.js
      initThree() {
        this.scene = new THREE.Scene();


        // Establecer fondo blanco para la escena
        this.renderer = new THREE.WebGLRenderer({ antialias: true });
        this.renderer.setSize(window.innerWidth, window.innerHeight / 2);
        this.renderer.setClearColor(0xffffff);  // Fondo blanco
        this.threejsContainer.nativeElement.appendChild(this.renderer.domElement);

        // Configuración de la cámara
        this.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
        this.camera.position.set(0, 30.5, 100);  // Una distancia prudente desde la cámara
        this.camera.lookAt(0, 0 , 0);  // Aseguramos que mire hacia el centro del modelo

        // Controles de órbita para rotar y hacer zoom en el modelo 3D
        this.controls = new OrbitControls(this.camera, this.renderer.domElement);
        this.controls.enableDamping = true;

        // Limitar el zoom
      this.controls.minDistance = 70;  // Distancia mínima para acercarse
      this.controls.maxDistance = 120; // Distancia máxima para alejarse

        // Iluminación
        const light = new THREE.AmbientLight(0xffffff, 1.5);
        this.scene.add(light);

        const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
        directionalLight.position.set(1, 1, 0).normalize();
        this.scene.add(directionalLight);

        // Cargar el modelo 3D
        const objLoader = new OBJLoader();
        const mtlLoader = new MTLLoader();

        mtlLoader.load('https://firebasestorage.googleapis.com/v0/b/aborigen-99669.appspot.com/o/modelos3d%2Fmateriales%2Fcamiseta1.mtl?alt=media&token=f847ee18-ffa1-4df5-90d1-6b80cb8e4647', (materials) => {
          materials.preload();
          objLoader.setMaterials(materials);
          objLoader.load('https://firebasestorage.googleapis.com/v0/b/aborigen-99669.appspot.com/o/modelos3d%2Fobjetos%2Fcamiseta1.obj?alt=media&token=b0c4bdef-9725-421a-a5d4-03076749eee7', (object) => {
            this.model = object;

            // Centramos el modelo frente a la cámara
            this.model.position.set(0, -30, 0);  // Ajustar la posición del modelo en Y si es necesario

            this.scene.add(this.model);
          });
        });

        this.animate();
      }

      // Animación de la escena 3D
      animate() {
        requestAnimationFrame(() => this.animate());
        if (this.controls) this.controls.update();
        this.renderer.render(this.scene, this.camera);
      }

      updateTextureInRealTime() {
        if (!this.canvas || !this.model || !this.selectedImage) {
            console.log("Canvas, modelo 3D o imagen seleccionada no están inicializados.");
            return;
        }

        // Encuentra la imagen guía en el canvas
        const guideImage = this.canvas.getObjects().find(obj => obj.type === 'image' && !obj.selectable);

        // Oculta temporalmente la imagen guía para que no aparezca en la textura 3D
        if (guideImage) {
            guideImage.set('visible', false);
            this.canvas.renderAll(); // Refresca el canvas sin la imagen guía visible
        }

        // Crear una textura desde el canvas de Fabric.js
        const texture = new THREE.CanvasTexture(this.previewCanvas.nativeElement);
        texture.needsUpdate = true;

        // Aplicar la textura al modelo 3D
        this.model.traverse((child: any) => {
            if (child.isMesh) {
                const material = new THREE.MeshPhongMaterial({
                    map: texture, // Asignar la textura del canvas
                    combine: THREE.MixOperation, // Permitir que la textura domine sobre el color
                });

                // Asignar el material al objeto del modelo
                child.material = material;
                child.material.needsUpdate = true;
            }
        });

        // Restaurar la visibilidad de la imagen guía en el canvas 2D
        if (guideImage) {
            guideImage.set('visible', true);
            this.canvas.renderAll(); // Refresca el canvas para mostrar de nuevo la imagen guía
        }
    }

    async comprar(){
      this.router.navigate(['/carrito'])
      this.carrito.addProduct(this.producto, await this.carrito.reduce([this.talla]))
    }

    talla = {
      "talla": "xl",
      "cantidad": 1,
      "color": "https://firebasestorage.googleapis.com/v0/b/aborigen-99669.appspot.com/o/Dise%C3%B1aTuprenda%2Fimages.jpeg?alt=media&token=a00bb7c9-93f8-4460-a816-4f061d37e205"
  }

    producto =
      {
        "style": [
            "urbano",
            "oversize"
        ],
        "id": "8HtbBSWTP3rw1adKUW1m",
        "category": "camisetas",
        "price": 30000,
        "offer": false,
        "colors": false,
        "gender": "hombres",
        "sizes": [
            "xl",
            "l",
            "m",
            "s"
        ],
        "image": [
            {
                "url": "https://firebasestorage.googleapis.com/v0/b/aborigen-99669.appspot.com/o/Dise%C3%B1aTuprenda%2Fimages.jpeg?alt=media&token=a00bb7c9-93f8-4460-a816-4f061d37e205"
            },
            {
                "url": "https://firebasestorage.googleapis.com/v0/b/aborigen-99669.appspot.com/o/Dise%C3%B1aTuprenda%2Fimages.jpeg?alt=media&token=a00bb7c9-93f8-4460-a816-4f061d37e205"
            }
        ],
        "name": "camiseta personalizada",
        "priceBefore": null,
        "date": {
            "seconds": 1681419964,
            "nanoseconds": 997000000
        },
        "provedor": "de la rosa",
        "description": "camiseta con estampado personalizado "
    }


      // Inicializa Fabric.js
      initFabric(color: string) {
        const width = window.innerWidth ;
        const height = window.innerHeight / 2;
        this.canvas = new fabric.Canvas(this.previewCanvas.nativeElement, {
          width: width,
          height: height,

          backgroundColor: color

        });

          // Evento para detectar cualquier modificación (movimiento, escala, rotación)
  this.canvas.on('object:modified', (e: any) => {
    if (e.target && e.target instanceof fabric.Image) {
      this.scheduleControlsDeactivation(e.target);
    }
  });
  // Evento para reactivar controles cuando se hace clic en una imagen
  this.canvas.on('mouse:down', (e: any) => {
    if (e.target && e.target instanceof fabric.Image) {
      e.target.hasControls = true;
      e.target.hasBorders = true;
      this.canvas.renderAll(); // Refresca el canvas para aplicar los cambios
    }
  });
        // Eventos para detectar movimientos y actualizaciones en la imagen
        this.canvas.on('object:moving', () => this.updateTextureInRealTime());
        this.canvas.on('object:scaling', () => this.updateTextureInRealTime());
        this.canvas.on('object:rotating', () => this.updateTextureInRealTime());



      }


      scheduleControlsDeactivation(image: fabric.Image) {
        // Desactivar los controles después de 3 segundos
        setTimeout(() => {
          image.hasControls = false;
          image.hasBorders = false;
          this.canvas.renderAll(); // Refresca el canvas para aplicar los cambios
          this.updateTextureInRealTime()
        }, 3000); // 3 segundos
      }
      // Carga una imagen seleccionada y la agrega al canvas de Fabric.js
      onFileSelected(event: any) {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = (e: any) => {
          fabric.Image.fromURL(e.target.result, (img) => {
            this.selectedImage = img;

            // Calcula el tamaño de la imagen para que sea 1/8 del tamaño del canvas
            const scaleFactor = 1 / 8;
            const canvasWidth = this.canvas.getWidth();
            const canvasHeight = this.canvas.getHeight();
            const scaledWidth = canvasWidth * scaleFactor;
            const scaledHeight = canvasHeight * scaleFactor;

            this.selectedImage.set({
              left: 60, // Esquina inferior izquierda
              top: (canvasHeight - scaledHeight) - 100, // Ajustar para que esté en la parte inferior
              scaleX: scaledWidth / this.selectedImage.width!,
              scaleY: scaledHeight / this.selectedImage.height!,
              selectable: true,  // Permitir la selección
              hasControls: true, // Mostrar controles
              hasBorders: true,  // Mostrar bordes
              lockMovementX: false, // Permitir movimiento en X
              lockMovementY: false  // Permitir movimiento en Y
            });

            // Añadir la nueva imagen al canvas
            this.canvas.add(this.selectedImage!);

            // Forzar que la imagen nueva esté en la parte superior
            this.moveImageToTop(this.selectedImage);

            // Actualizar la textura en tiempo real
            this.updateTextureInRealTime();

            // Refrescar el canvas
            this.canvas.renderAll();
          });
        };

        reader.readAsDataURL(file);
      }

      // Método para mover la imagen al frente sin importar la situación
      moveImageToTop(image: fabric.Image) {
        // Elimina y vuelve a agregar todos los objetos en el orden correcto
        const allObjects = this.canvas.getObjects().filter(obj => obj !== image);

        // Limpia el canvas
        this.canvas.clear();
        this.canvas.setBackgroundColor(this.color, this.canvas.renderAll.bind(this.canvas));
        // Añadir de nuevo todos los objetos, dejando la imagen al final (en la parte superior)
        allObjects.forEach(obj => this.canvas.add(obj));

        // Finalmente, añade la nueva imagen para asegurarse de que esté en la parte superior
        this.canvas.add(image);

        // Refresca el canvas
        this.canvas.renderAll();
      }


      colors = [
        { label: 'Rojo', color: 'red' },
        { label: 'Azul', color: 'blue' },
        { label: 'Verde', color: 'green' },
        { label: 'Amarillo', color: '#ffc409' },
        { label: 'Negro', color: '#000000' },
        { label: 'Blanco', color: 'white' },
      ];

      loadGuideImage(imageUrl: string) {
        fabric.Image.fromURL(imageUrl, (img: fabric.Image) => {
          img.set({
            selectable: false, // No seleccionable
            evented: false,    // No responde a eventos
            opacity: 0.5,     // Ajusta la opacidad si es necesario
            visible: true      // Asegúrate de que sea visible en el canvas
          });

          const canvasWidth = this.canvas.width;
          const canvasHeight = this.canvas.height;

          // Escalar la imagen guía
          img.scaleToWidth(canvasWidth);
          // img.scaleToHeight(canvasHeight); // Ajustar la altura si es necesario

          this.canvas.add(img); // Añade la imagen al canvas
          img.center(); // Centra la imagen en el canvas
          this.canvas.renderAll(); // Refresca el canvas
        });
      }



// Método para exportar el modelo
exportModel() {
  // Ocultar la imagen guía
  const guideImage = this.canvas.getObjects().find(obj => obj.type === 'image' && !obj.selectable);
  if (guideImage) {
    guideImage.set('visible', false); // Oculta la imagen guía
  }

  // Realiza la exportación
  const json = this.canvas.toJSON();
  console.log(json); // Aquí puedes usar el JSON como desees

  // Si quieres restaurar la visibilidad después de la exportación
  if (guideImage) {
    guideImage.set('visible', true); // Muestra la imagen guía nuevamente
  }
}

    }
