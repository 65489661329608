import { Component } from '@angular/core';
import { AuthService } from './services/auth.service';
import { NotificationsServiceService } from './services/notifications-service.service';
import { StatusBar, Style, StatusBarInfo, BackgroundColorOptions } from '@capacitor/status-bar';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  constructor(
    private auth : AuthService,
    private notifications: NotificationsServiceService,
    public platform : Platform,
    ) {
      if (this.platform.is('capacitor')){
      // color status bar
          let options:BackgroundColorOptions={
            color : "#000000"
          }
          StatusBar.setBackgroundColor(options)
      }

  }
  whatsapp(){
    window.open("https://wa.me/+573118084657");
  }

}
