import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { product } from 'src/app/models/interfaces';
import { AlertsService } from 'src/app/services/alerts.service';
import { CarritoService } from 'src/app/services/carrito.service';
import { PreloadImgService } from 'src/app/services/preload-img.service';

@Component({
  selector: 'app-producto',
  templateUrl: './producto.component.html',
  styleUrls: ['./producto.component.scss'],
})
export class ProductoComponent implements OnInit {

  @Input() producto : product
  imgDefault = this.preloadImg.defaultImg
  constructor(public carrito: CarritoService,
    private fb: FormBuilder,
    private alersSv : AlertsService,
    public router : Router,
    private preloadImg : PreloadImgService, ) { }

    formCantidad = this.fb.group({
      talla: [null, Validators.required],
      cantidad: [1, Validators.required]
    })
  
  addProd =[]

  ngOnInit() {
    this.formCantidad.valueChanges.subscribe(
      res =>{
        this.addProd = []
        this.addProd.push(res)
      }
    )
  }
  async addProduct(){
    console.log("add desde producto");
    
    if (this.carrito.auth.uid) {
      if (this.producto.colors) {
        this.router.navigate(['/detailproduct',this.producto.id])
        return
      }
      if (this.addProd.length < 1 && !this.producto.colors) {
        this.alersSv.presentAlert('por favor selecciona la talla del producto')
      } else {
        await this.carrito.addProduct(this.producto, this.addProd) ;
        this.alersSv.presentToast('bottom', 'Producto agregado al carrito')
        this.formCantidad.reset()
      }
    } 
    else{
      this.alersSv.presentAlert('Para hacer un pedido debes iniciar sesion')
      this.router.navigate(['/singin'])
    }
  }

}
