import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { FirestoreService } from 'src/app/services/firestore.service';
import { IonInfiniteScroll } from '@ionic/angular';

@Component({
  selector: 'app-pedidos',
  templateUrl: './pedidos.component.html',
  styleUrls: ['./pedidos.component.scss'],
})
export class PedidosComponent implements OnInit, OnDestroy {

  @ViewChild(IonInfiniteScroll) infiniteScroll : IonInfiniteScroll
  constructor(private firestore : FirestoreService,
    public auth : AuthService) { }
    disablescroll: boolean = false;
    path : string
    nuevosSuscriber : Subscription
    pedidos :any[]=[]
    pedidosEntregados :any[]=[]
    nuevos = true

  ngOnInit() {
    this.auth.stateAutho().subscribe((res)=>{
      if (res) {
         this.auth.uid = res['uid']
         this.path = `users/${this.auth.uid}/pedidos/`
         this.getNuevos()
      } 
    })
    
  }

  segmentChanged(ev: any) {
    let opc = ev.detail.value 
    if (opc == 'nuevos') {
      this.nuevos = true
      this.getNuevos()
    }
    if (opc == 'entregados') {
      this.nuevos = false
      this.getTerminados()
    }
  }
  getNuevos(){
    let startAt = null
    if (this.pedidos.length) {
      startAt = this.pedidos[this.pedidos.length - 1].fecha
    }
    this.nuevosSuscriber = this.firestore.getCollectionQuery(this.path, 'estado', "==", "enviado", startAt).subscribe((res)=>{
      if (res) {
        res.forEach(pedido =>{
          this.pedidos.push(pedido)
        })

        var hash = {};
        this.pedidos = this.pedidos.filter(function(current) {
          var exists = !hash[current.id];
          hash[current.id] = true;
          return exists;
        });
      }
    })
  }
  getTerminados(){
    let startAt = null
    if (this.pedidosEntregados.length) {
      startAt = this.pedidosEntregados[this.pedidosEntregados.length - 1].fecha
    }
    
    this.nuevosSuscriber = this.firestore.getCollectionQuery(this.path, 'estado', "==", "entregado", startAt).subscribe((res)=>{
      if (res) {
        res.forEach(pedido =>{
          this.pedidosEntregados.push(pedido)
        })

        var hash = {};
        this.pedidosEntregados = this.pedidosEntregados.filter(function(current) {
          var exists = !hash[current.id];
          hash[current.id] = true;
          return exists;
        });
      }
    })
  }

  loadmost(){
    this.nuevos ?  this.getNuevos() : this.getTerminados()
    this.infiniteScroll.complete()
   }


  ngOnDestroy(): void {
    if (this.nuevosSuscriber) {
       this.nuevosSuscriber.unsubscribe()
    }
  }
}
