import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal-video',
  templateUrl: './modal-video.component.html',
  styleUrls: ['./modal-video.component.scss'],
})
export class ModalVideoComponent implements OnInit {

  constructor(
    private modalCrl : ModalController,
    ) { }

  ngOnInit() {
    setTimeout(() => {
      this.modalCrl.dismiss()
    }, 8000);
  }

  back(){
    this.modalCrl.dismiss()
  }


}
