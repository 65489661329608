import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertController, ModalController, PopoverController } from '@ionic/angular';
import { CheckoutPoppupDireccionComponent } from 'src/app/components/popover/checkout-poppup-direccion/checkout-poppup-direccion.component';
import { CheckoutPoppupPagoComponent } from 'src/app/components/popover/checkout-poppup-pago/checkout-poppup-pago.component';
import { CheckoutPoppupComponent } from 'src/app/components/popover/checkout-poppup/checkout-poppup.component';
import { GoogleMapsComponent } from 'src/app/google-maps/google-maps.component';
import { AlertsService } from 'src/app/services/alerts.service';
import { AuthService } from 'src/app/services/auth.service';
import { CarritoService } from 'src/app/services/carrito.service';
import { CommonService } from 'src/app/services/common.service';
import { FirestoreService } from 'src/app/services/firestore.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
})
export class CheckoutComponent implements OnInit {

  @Input() pedido ;
  @Input() total ;
  direccion = ""
  imgPoducts = [];
  descripcionDireccion = "(Opcional)"
  constructor(
    public modalcontrol : ModalController,
    public modalControler : ModalController,
    private fb: FormBuilder,
    public auth : AuthService, 
    private firestore : FirestoreService,
    private common : CommonService,
    public router : Router ,
    public carrito : CarritoService,
    private alersSv : AlertsService,
    private http: HttpClient,
    private alertController: AlertController,
    private formBuilder: FormBuilder,
    public popoverController: PopoverController
  ) { }
  get name(){return this.formClient.get('name') }
  get phone() {return this.formClient.get('phone') }
  get email() {return this.formClient.get('email') }
  get formPays(){return this.formPay.get('name') }

  user:any={}

  formClient = this.fb.group({
    uid:[''],
    name: ['', Validators.required],
    phone : ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
    ubication : [null],
    ubicationDescription:'',
    email : ['', [Validators.required] ],
  });

  formPay = this.fb.group({
    name: ['', Validators.required],
  });

  ngOnInit() {
    this.getPerfil()
    this.getImgPoducts()
  }

  async ubication(){ 
    // la ubicacion maps
    const modal = await this.modalcontrol.create({
      component: GoogleMapsComponent,
      mode: 'ios',
      swipeToClose : true,
      componentProps : {ubication : this.user.ubication, id : this.user.uid}
    })
    await modal.present()
    const data = await modal.onDidDismiss()
    this.formClient.get('ubication').setValue(data.data['pos'])
  }
  aceptar(){
  
    this.edit()
    //this.modalControler.dismiss({pos:'objeto a pasar'})
   }

   getImgPoducts(){
    this.pedido.productos.forEach(element => {
      this.imgPoducts.push(element.producto.image[0])
    });
    console.log(this.imgPoducts);
    
   }

   getPerfil(){
      this.auth.stateAutho().subscribe((res)=>{
        if (res) {
          this.auth.uid = res['uid']
          let path = `users/`
          this.firestore.getDoc(path, this.auth.uid).subscribe((res)=>{
              if (res) {
                this.user = res
               console.log('usuario', this.user);
             
                
              if (this.user.ubication) {
                
                  const TU_LLAVE = environment.apikeyGoogle;
                  this.http.get<{status: string, results: any[]}>(
                    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${this.user.ubication.lat},${this.user.ubication.lng}&key=${TU_LLAVE}`,
                    {responseType: 'json'}
                  ).subscribe(e => {
                    if (e.status === 'OK') {
                      this.direccion = e.results[0].formatted_address
                    }
                  });
                  this.setData()
                  this.setForm()
                }
              }
          })
        } 
      })
    }

    setData(){
      this.descripcionDireccion = this.user.ubicationDescription
    }


    setForm(){
      this.formClient.get('name').setValue(this.user['name'])
      this.formClient.get('phone').setValue(this.user['phone'])
      this.formClient.get('ubication').setValue(this.user['ubication'])
      this.formClient.get('ubicationDescription').setValue(this.user['ubicationDescription'])
      this.formClient.get('uid').setValue(this.user['uid'])
      this.formClient.get('email').setValue(this.user['email'])
    }

 

    async openPopUp(e: Event ) {
      const popover = await this.popoverController.create({
        component: CheckoutPoppupComponent,
        componentProps: {user: this.user }
      });
      popover.shadowRoot.lastChild.lastChild['style'].cssText = 'border-radius: 20px !important; padding : 5px';
      await popover.present();
      const { data } = await popover.onDidDismiss();
    }


    async openPopUpPago(e: Event ) {
      const popover = await this.popoverController.create({
        component: CheckoutPoppupPagoComponent,
        componentProps: {user: this.user }
      });
      popover.shadowRoot.lastChild.lastChild['style'].cssText = 'border-radius: 20px !important; padding : 20px';
      await popover.present();
      const { data } = await popover.onDidDismiss();
    }



    async openPopUpDireccion(e: Event ) {
      const popover = await this.popoverController.create({
        component: CheckoutPoppupDireccionComponent,
        componentProps: {user: this.user }
      });
      popover.shadowRoot.lastChild.lastChild['style'].cssText = 'border-radius: 20px !important; padding : 20px';
      await popover.present();
      const { data } = await popover.onDidDismiss();
    }




    async edit(){
      let path = 'users/'
        await this.common.showLoading('Cargando')
          await this.firestore.updateDoc(this.formClient.value, path, this.formClient.get('uid').value).then(()=>{
          this.common.load.dismiss()
          this.modalControler.dismiss()
          this.pay()
         })
      
    }



    async pay(){
      this.pedido.formaPago = this.user.formaPago
      this.pedido.precioTotal = this.total
      this.pedido.fecha = new Date()
      this.pedido.cliente = this.auth.user
      this.pedido.id = this.firestore.createId()
      const path = `users/${this.auth.uid}/pedidos/`
      await this.firestore.createDoc(this.pedido,path, this.pedido.id)
      this.carrito.clearCart()
      this.alersSv.presentAlert("Pedido realizado con exito, pronto nos contactaremos")
      this.router.navigate(['/home'])
      
    }


    close(){
      this.modalControler.dismiss()
    }
  }
  
  


