import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { CarritoService } from 'src/app/services/carrito.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit, OnDestroy {

  formLogin = this.fb.group({
    email : ['', Validators.required],
    password : ['', Validators.required]
  });
  invalid = false

  constructor(
    private fb: FormBuilder,
    private auth : AuthService,
    private router : Router,
   // private alert : AlertsService,
    private carritoSv : CarritoService    ) { }

  ngOnInit() {}

  async login(){
    let email = this.formLogin.get('email').value
    let pass = this.formLogin.get('password').value
    try {
      await this.auth.login(email,pass)
      this.router.navigate(['/'])
      this.carritoSv.initCart()
      this.carritoSv.loadCart()
      this.formLogin.reset()
    } catch (error) {
      //this.alert.presentAlert('Usuario o contraseña incorrectos')
      this.invalid = true
      
    }
    
  }

  resgister(){
    this.router.navigate(['/singup'])
  }

  ngOnDestroy(): void {
    this.invalid = false
    
  }
   
}
