import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { LoadingController, NavParams, PopoverController, ToastController } from '@ionic/angular';
import { product } from 'src/app/models/interfaces';
import { CommonService } from 'src/app/services/common.service';
import { FirestoreService } from 'src/app/services/firestore.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-edit-products',
  templateUrl: './edit-products.component.html',
  styleUrls: ['./edit-products.component.scss'],
})
export class EditProductsComponent implements OnInit {
  producto;
  newImage: string ='';
  load : any
  arrayImagenes=[]
  arrayImgSubir=[]
  path = 'productos/'
  generos: any[];
  default = 'Hombre'
  categorias: any[];
  styles: any[];
  sizes: any[];
  get name (){return this.formProduct.get('name')}
  get price (){return this.formProduct.get('price')}
  get description (){return this.formProduct.get('description')}
  get image (){return this.formProduct.get('image')}
  get gender (){return this.formProduct.get('gender')}
  get category (){return this.formProduct.get('category')}
  get style (){return this.formProduct.get('style')}

  constructor( 
    private storage : StorageService,
    private popocerCrl : PopoverController,
    private navParams: NavParams,
    private fb: FormBuilder,
    private toast : ToastController,
    private firestore : FirestoreService,
    private common : CommonService
  ) { 
    this.producto = {
      gender : 'genero'
    }
  }

  formProduct = this.fb.group({
    price: [null, [Validators.required]],
    provedor: [null, [Validators.required]],
    name: [null,[Validators.required]],
    gender: [null,[Validators.required]],
    category: [null,[Validators.required]],
    style: [null,[Validators.required]],
    priceBefore :[null],
    image:[null,[Validators.required]],
    description:[null,[Validators.required]],
    id :[null],
    date :new Date(),
    offer : [false],
    colors : [false],
    sizes : [null,[Validators.required]],
  });

  formulario = this.fb.group(
    {lessons : this.fb.array([])}
  )

  get lessons(){
    return this.formulario.controls['lessons'] as FormArray
  }



    lessonsFrom = this.fb.group({
      img :['', Validators.required]
    })
  addLessons(){
    this.lessons
  }

  ngOnInit() {
    this.producto = this.navParams.get('producto')
    if (this.producto) {
      this.formProduct.patchValue(this.producto )
    }
    console.log(this.producto);
    
    this.getGender()
    this.getCategory()
    this.getEstyles()
    this.getSize()
  }

  getSize(){
    let path = 'sizes/'
    this.firestore.getCollection(path).subscribe(res =>{
      this.sizes = res
      this.sizes.sort((a, b) => a.number < b.number ? 1 : a === b ? 0 : -1)
      
    })
  }

  async newImg(photo:any){
    try {
      this.arrayImgSubir.push(photo.target.files[0])
      if (photo.target.files && photo.target.files[0]) {
        const reader = new FileReader();
        reader.onload = ((img)=>{
          this.lessonsFrom = this.fb.group({
            img :img.target.result as string
          })
          this.lessons.push(this.lessonsFrom)
        this.arrayImagenes.push(img.target.result as string) 
        
        });
        reader.readAsDataURL(photo.target.files[0])
      }
    } catch (error) {
      this.formProduct.get('image').setValue(null)
      this.presentToast('no se puede cargar la foto en este momento '+ error)
    }
  }

  removeImg(img:any, i:number){
    this.producto['image'].splice(i,1)
  }

  getGender(){
    this.firestore.getCollection('genero/').subscribe(res =>{
      this.generos = res
    })
  }

  getCategory(){
    this.firestore.getCollection('categorias/').subscribe(res =>{
      this.categorias = res
    })
  }

  getEstyles(){
    this.firestore.getCollection('styles/').subscribe(res =>{
      this.styles = res
    })
  }

  async saveProduct(){ 
   console.log(this.formProduct.value);
   
    if (this.arrayImagenes.length != 0) {
      this.formProduct.controls['image'].clearValidators()
      this.formProduct.controls['image'].updateValueAndValidity()
    }
    if (this.formProduct.valid) {
    
      let array:any= []
      let cuenta : number = 0
      await this.common.showLoading('Cargando')
      if (this.formProduct.get('id').value) {

        try {
        if (this.arrayImgSubir.length > 0) {
          this.arrayImgSubir.forEach(    async element => {
          try {
           let imgproduct = this.producto.image
            const path = 'productos'
            const name = element['name'] + new Date()
            console.log(element);
            
            console.log(element,path,name);
            
            await this.storage.uploadImg(element,path,name).then(
              async (res)=>{
              array.push({url :res})
              cuenta ++
              if (cuenta == this.arrayImgSubir.length) {
                this.formProduct.get('image').setValue(array.concat(imgproduct))
                this.firestore.updateDoc(this.formProduct.value, this.path, this.formProduct.get('id').value).then(()=>{this.common.load.dismiss()})
                this.presentToast('Producto editado')
                this.common.load.dismiss()
              }
              })
          } catch (error) {
            console.log(error);
          }
        })
        }else{
          this.firestore.updateDoc(this.formProduct.value, this.path, this.formProduct.get('id').value).then(()=>{this.common.load.dismiss()})
    
          this.presentToast('Producto editado')
          this.common.load.dismiss()
        }  
        } catch (error) {
          this.presentToast('No se pudo editar revisa tu conexion')
        }
      }else{
      this.formProduct.get('id').setValue(this.firestore.createId())
        let id = this.formProduct.get('id').value
        try {
        this.arrayImgSubir.forEach(    async element => {
            try {
              const path = 'productos'
              const name = element['name'] + new Date()
              
              console.log(element,path,name);
              
              await this.storage.uploadImg(element,path,name).then(
                async (res)=>{
                array.push({url :res})
                this.formProduct.get('image').setValue(array)
                cuenta ++
                if (cuenta == this.arrayImgSubir.length) {
                  await this.firestore.createDoc(this.formProduct.value, this.path,id )
                  this.presentToast('Producto creado')
               
                  this.common.load.dismiss()
                }
                })
            } catch (error) {
              console.log(error);
            }
          })
        } catch (error) {
          console.log(error);
          this.presentToast('No se pudo guardar revisa tu conexion')
          this.common.load.dismiss()
        }

      }
      this.dismissClick()
    }else{
      this.formProduct.markAllAsTouched()
      
    }
    
  }





  async presentToast(mensaje:string) {
    const toast = await this.toast.create({
      message: mensaje,
      duration: 500,
      color: 'light'
    });
    toast.present();
  }


  deleteLessons(lessonsIndex : number){
    this.lessons.removeAt(lessonsIndex)
    this.arrayImagenes.splice(lessonsIndex,1)
  }



  ngOnDestroy(): void {
    this.producto = null
  
  }

  async dismissClick() {
    await this.popocerCrl.dismiss();
  }


}
