import { Pipe, PipeTransform } from '@angular/core';
import { product } from '../models/interfaces';

@Pipe({
  name: 'filtro'
})
export class FiltroPipe implements PipeTransform {

  transform(array: product[], texto: string=''): any {
    if (texto == '' || texto == ' ') {
      return array
    }
    if (!array) {
      return array
    }
    texto = texto.toLocaleLowerCase()
    return array.filter(
       item=>item['name'].toLowerCase().includes(texto))
    };

  

}
