import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { distinctUntilChanged } from 'rxjs/operators';
import { FirestoreService } from 'src/app/services/firestore.service';

@Component({
  selector: 'app-design-garment',
  templateUrl: './design-garment.component.html',
  styleUrls: ['./design-garment.component.scss'],
})
export class DesignGarmentComponent implements OnInit {
  categorias: any[];
  genero: string;

  constructor(public firestore: FirestoreService, 
    public router: Router, public route: ActivatedRoute) { }

  async ngOnInit() {
    this.getCategories();
    this.genero = await this.route.snapshot.params['genero'];
    console.log(this.genero);
  }

  getCategories() {
    const path = 'categoriasTuPrenda';
    this.firestore.getCollection(path).pipe(distinctUntilChanged()).subscribe(res => {
      if (res) {
        this.categorias = res;
        this.categorias.reverse();
      }
    });
  }

  goDirect(name){
    console.log(name);
    this.router.navigate([`selectColor/stock/${this.genero}`]);
  }

}
