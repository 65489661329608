import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FirestoreService } from 'src/app/services/firestore.service';
import {CommonService} from 'src/app/services/common.service'
import { PreloadImgService } from 'src/app/services/preload-img.service';
import { LazyLoadImageModule, LAZYLOAD_IMAGE_HOOKS, ScrollHooks } from 'ng-lazyload-image'; // <-- include ScrollHooks

@Component({
  selector: 'app-estilo',
  templateUrl: './estilo.component.html',
  styleUrls: ['./estilo.component.scss'],
})
export class EstiloComponent implements OnInit {
  genero : any = [];
  styles = [];
  imgDefault = this.preloadImg.defaultImg
  designCollection: any;
  constructor(
    private preloadImg : PreloadImgService,
    private router : Router,
    private route: ActivatedRoute,
    private firebase : FirestoreService,
    private common : CommonService
    ) { }

  async ngOnInit() {


    this.genero = this.route.snapshot.params["genero"];
    console.log(this.genero);
    await this.getStyles()
    await this.getSDesign()
  }

  isOdd(length: number): boolean {
    return length % 2 !== 0;
  }
  getStyles(){
    let path = "styles"
    this.firebase.getCollection(path).subscribe(res=>{
      this.styles = res
      if (res) {
        this.styles = res
        console.log('estilos', this.styles);
      }
      this.styles.forEach(element => {
        if (element.hasOwnProperty(this.genero)) {
          element.ver = true
        }
       });
    })
  }

  getSDesign(){
    let path = "designCollection"
    this.firebase.getCollection(path).subscribe(res=>{
      this.designCollection = res
      if (res) {
        this.designCollection = res
        console.log('diseña', this.designCollection);
      }
      this.designCollection.forEach(element => {
        if (element.hasOwnProperty(this.genero)) {
          element.ver = true
        }
       });
    })
  }

  goDesignGarment(){
    this.router.navigate([`/selectColor/stock/${this.genero}`])
  }

  navigate(item){
    this.router.navigate([`/search/${this.genero}/${item.name}`])
  }
}
