import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { PopoverController } from '@ionic/angular';
import { FirestoreService } from 'src/app/services/firestore.service';

@Component({
  selector: 'app-checkout-poppup-direccion',
  templateUrl: './checkout-poppup-direccion.component.html',
  styleUrls: ['./checkout-poppup-direccion.component.scss'],
})
export class CheckoutPoppupDireccionComponent implements OnInit {
  @Input() user:any ={};
  constructor(
    private popoverController: PopoverController, 
    private fb: FormBuilder,
    private firestore : FirestoreService
  ) { }
  

  formData = this.fb.group({
    ubicationDescription : [this.user.ubicationDescription, [Validators.required, Validators.minLength(6)]],
  })
  ngOnInit() {
    this.formData.patchValue({
      ubicationDescription : this.user.ubicationDescription,
    })
  }

  aceptar(){
    let path = "users"
    this.firestore.updateDoc( {ubicationDescription: this.formData.get('ubicationDescription').value},path,this.user.uid)
    this.popoverController.dismiss()
  }

}
