import { Injectable } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AlertsService {
  constructor( 
    private alertController: AlertController,
    private toastController: ToastController
    ) { }

  async presentAlert(mensaje){
    const alert = await this.alertController.create({
      header: mensaje ,
      cssClass: 'custom-alert',
      buttons: [
        {
          text: 'ok',
          cssClass: 'alert-button-cancel',
          handler:(blah)=>{
          }
        },
      ]
    });
    await alert.present();
  }


  async presentToast(position: 'top' | 'middle' | 'bottom', mensaje) {
    const toast = await this.toastController.create({
      message:  mensaje,
      duration: 1800,
      position: position
    });

    await toast.present();
  }

  
 
}
