import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  load: any;
  uid: string;
  user: any;
  constructor(public auth : AngularFireAuth
   ) { 
    this.getUid()
   }

  login(email:string , password : string){
    return this.auth.signInWithEmailAndPassword(email,password)
  }

  logout(){
     this.auth.signOut()
     
  }

  register(email:string , password : string){
    return this.auth.createUserWithEmailAndPassword(email , password )
  }

  async getUid(){
    const user = await this.auth.currentUser
    if (user) {
      return user.uid
    }
  }

  stateAutho(){
    return this.auth.authState.pipe(map((auth) => auth));
  }

 

}
