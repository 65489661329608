import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { product } from '../models/interfaces';
@Injectable({
  providedIn: 'root'
})
export class FirestoreService {
  order:any = 'desc';
  category: string = null;
  limit : number = 15
  mayor: boolean;

  constructor(private database: AngularFirestore) { }



  createDoc(data: any, path: string, id : any){
    const collection = this.database.collection(path)
    return collection.doc(id).set(data)
  }

  getCollection<product>(path:string){
    const collection = this.database.collection<product>(path)
    return collection.valueChanges();
    
  }

  getCollectionQuery<product>(path:string, parametro:string, condicion : any, busqueda, startAt){
    if (!startAt) {
      startAt = new Date()
    }
    const collection = this.database.collection<product>(path, ref => ref.where(parametro,condicion,busqueda)
    .orderBy('fecha', 'desc').limit(5).startAfter(startAt)
    )
    return collection.valueChanges();
  }

  getCollectionAll(path:string, parametro:string, condicion : any, busqueda, startAt){
    if (!startAt) {
      startAt = new Date()
    }
    const collection = this.database.collectionGroup<any>(path, ref => ref.where(parametro,condicion,busqueda)
    .orderBy('fecha', 'desc').limit(2).startAfter(startAt)
    )
    return collection.valueChanges()
  }
  primero = true
  getCollectionProductos(path:string, parametro:string, condicion : any, busqueda, parametro2, busqueda2, orderbylist, startAt ){

    
    if(!startAt){
     switch (orderbylist) {
      case 'date':
        startAt = new Date()
        this.primero = false
        break;
      case 'price':
        startAt = '0'
        break;
      default:
        break;
     }
    }
    let collection :any
    switch (this.primero) {
      case true:
            if (this.category != 'todo') {
             if (this.mayor) {
              collection = this.database.collection<any>(path, ref => ref.where(parametro2, 'array-contains', busqueda2).where(parametro,condicion,busqueda ).orderBy(orderbylist, this.order)
              .limit(this.limit)
              .startAfter(startAt)
            )
            }else{
              collection = this.database.collection<any>(path, ref => ref.where(parametro2, 'array-contains', busqueda2).where(parametro,condicion,busqueda ).where('category', condicion, this.category)
              .orderBy(orderbylist, 'asc')
              .limit(this.limit)
              .endAt(startAt)
              )}
          
            }
            this.primero = false
            if (this.category == 'todo') {
              if (this.mayor) {
                collection = this.database.collection<any>(path, ref => ref.where(parametro2, 'array-contains', busqueda2).where(parametro,condicion,busqueda ).orderBy(orderbylist, this.order)
                .limit(this.limit)
                .startAfter(startAt)
              )
              }else{

                collection = this.database.collection<any>(path, ref => ref.where(parametro2, 'array-contains', busqueda2).where(parametro,condicion,busqueda ).orderBy
                (orderbylist, 'asc')
                .limit(this.limit)
                .endAt(startAt)
                )
              }
         
            }
        break;
      case false:
            if (this.category != 'todo'){
              console.log(5);
              console.log(startAt);
            collection = this.database.collection<any>(path, ref => ref.where(parametro2, 'array-contains', busqueda2).where(parametro,condicion,busqueda ).where('category', condicion, this.category).orderBy(orderbylist, this.order)
            .limit(this.limit)
            .startAfter(startAt)
           )}
            if (this.category == 'todo') {
              console.log(6);
              console.log(startAt);
              
              collection = this.database.collection<any>(path, ref => ref.where(parametro2, 'array-contains', busqueda2).where(parametro,condicion,busqueda ).orderBy(orderbylist, this.order)
              .limit(this.limit)
              .startAfter(startAt)
            )
            }
        
        break;
    
      default:
        break;
    }

 
    this.mayor = false
   return collection.valueChanges()
  }



  getPromociones(path:string, parametro:string, condicion : any, busqueda,  orderbylist, startAt ){
    if(!startAt){
     switch (orderbylist) {
      case 'date':
        startAt = new Date()
        this.primero = false
        break;
      case 'price':
        startAt = '0'
        break;
      default:
        break;
     }
    }
    let collection :any

    if (this.primero) {
      if (this.mayor) {
        collection = this.database.collection<any>(path, ref => ref.where(parametro,condicion,busqueda ).where('offer', condicion, true).orderBy(orderbylist, this.order)
        .limit(this.limit)
        .startAfter(startAt)
      )
      }else{
        collection = this.database.collection<any>(path, ref => ref.where(parametro,condicion,busqueda ).where('offer', condicion, true)
        .orderBy(orderbylist, 'asc')
        .limit(this.limit)
        .endAt(startAt)
        )
      }
      this.primero = false
    
    }else{ 
      collection = this.database.collection<any>(path, ref => ref.where(parametro,condicion,busqueda ).where('offer', condicion, true).orderBy(orderbylist, this.order)
      .limit(this.limit)
      .startAfter(startAt)
    )
    }
   this.mayor = false
   return collection.valueChanges()
  }

  getTodo(path:string, parametro:string, condicion : any, busqueda,  orderbylist, startAt ){
    if(!startAt){
     switch (orderbylist) {
      case 'date':
        startAt = new Date()
        this.primero = false
        break;
      case 'price':
        startAt = '0'
        break;
      default:
        break;
     }
    }
    let collection :any

    if (this.primero) {
        collection = this.database.collection<any>(path, ref => ref.where(parametro,condicion,busqueda ).where('offer', condicion, true)
        .orderBy(orderbylist, 'asc')
        .limit(this.limit)
        .endAt(startAt)
      )
      this.primero = false
    
    }else{ 
      collection = this.database.collection<any>(path, ref => ref.where(parametro,condicion,busqueda ).where('offer', condicion, true).orderBy(orderbylist, this.order)
      .limit(this.limit)
      .startAfter(startAt)
    )
    }
   return collection.valueChanges()
  }



  search(nombre, gender){
    let collection :any
    collection = this.database.collection<any>('productos', ref => ref.where("gender","==", gender ).where('name', '>=', nombre)
    .where('name', '<=', nombre+ '\uf8ff')
  )
  return collection.valueChanges()
  }
  
  
  getCollectionPage(path:string, limit:number, startAt:any){
    if (!startAt) {
      startAt = new Date()
    }
    const collection = this.database.collection<any>(path, ref => ref.orderBy('fecha', 'desc').limit(limit).startAfter(startAt)
    )
    return collection.valueChanges()
  }

  getDoc(path:string,id:string){
    const collection = this.database.collection(path)
    return collection.doc(id).valueChanges()
  }

  deleteDoc(path:string,id :string){
    const collection = this.database.collection(path)
    return collection.doc(id).delete()
  }
  
  updateDoc(data:any, path:string,id :string){
    const collection = this.database.collection(path)
    return collection.doc(id).update(data)
  }

  createId(){
    return this.database.createId()
  }
}
