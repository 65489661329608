import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { FirestoreService } from '../services/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class RutesGuard implements CanActivate {


  constructor(private _router:Router,  
    public auth : AuthService,
    private firestore : FirestoreService,  ) {
      this.getAuth()
  }
  authentication : Subscription;
  admin = false

  getAuth(){
    this.auth.stateAutho().subscribe((res)=>{
      if (res) {
         this.auth.uid = res['uid']
          this.getUserInfo(this.auth.uid)
      } else{
        this.auth.user = null
        this.auth.uid = null
      }
    })
  }


  getUserInfo( id){
    const path = 'users'
      this.authentication = this.firestore.getDoc(path, id).subscribe(res=>{
        this.auth.user = res
        this.auth.user.hasOwnProperty('admin') ? this.admin = true : this.admin = false
    }) 
  }


  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): boolean {

      //check some condition  
      if (this.admin)  {
          return true;
      } 
      return false;
  }
}
