import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { pedido, productPedido } from 'src/app/models/interfaces';
import { AuthService } from 'src/app/services/auth.service';
import { CarritoService } from 'src/app/services/carrito.service';
import { FirestoreService } from 'src/app/services/firestore.service';
import { CheckoutComponent } from '../checkout/checkout.component';

@Component({
  selector: 'app-carrito',
  templateUrl: './carrito.component.html',
  styleUrls: ['./carrito.component.scss'],
})
export class CarritoComponent implements OnInit, OnDestroy {
  pedido ;
  subscriptionCart: Subscription;
  total: number = 0
  cantidadProd: number = 0;
  constructor(
    
    private modalcontroler : ModalController,
    private firestore : FirestoreService,
    public auth : AuthService,
    public router : Router ,
    public carrito : CarritoService,
    private alertController: AlertController,
    ) { 
      this.pedido ={}
    }

  async ngOnInit() {
    this.getCarrito()
  }

  getCarrito(){
    this.subscriptionCart = this.carrito.getCart().subscribe(res =>{
      if (res['productos']) {
        this.cantidadProd = res['productos'].length
        console.log(this.cantidadProd);
        
      }
      let totalproductos = []
      this.pedido = res
      this.total = 0
      this.pedido['productos'].forEach(element => {
        console.log(element);
        let reduce =  element['cantidad'].reduce((acumulador, actual) => acumulador + actual['cantidad'], 0);
        console.log(reduce);
        let totalproducto = reduce * element['producto']['price']
        console.log(totalproducto);
        totalproductos.push(totalproducto)
        
      });
       console.log(this.pedido.productos);
       this.total =  totalproductos.reduce((acumulador, actual) => acumulador + actual, 0);
     
      
    })
  }

  async checkout(){ 
    // la ubicacion maps
    const modal = await this.modalcontroler.create({
      component: CheckoutComponent,
      mode: 'ios',
      swipeToClose : true,
      componentProps : {pedido : this.pedido, total : this.total}
    })
    await modal.present()

    const data = await modal.onDidDismiss()
    
  }

  async cleanCart(){
    let mensaje ="Se eliminaran todos los productos del carrito, desea continuar ?"
    const alert = await this.alertController.create({
      header: mensaje ,
      cssClass: 'custom-alert',
      buttons: [
        {
          text: 'cancelar',
          role: 'cancel',
          handler:(blah)=>{
       
          },
        },
        {
          text: 'ok',
          cssClass: 'alert-button-cancel',
          handler:(blah)=>{
            this.carrito.clearCart()
          }
        },
      ]
    });
    await alert.present();
    
  }

  home(){
    this.router.navigate(['/home'])
  }
  ngOnDestroy(): void {
   this.subscriptionCart.unsubscribe()
  }
}
