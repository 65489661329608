import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IonInfiniteScroll } from '@ionic/angular';
import { Subscriber, Subscription } from 'rxjs';
import { product } from 'src/app/models/interfaces';
import { AuthService } from 'src/app/services/auth.service';
import { CarritoService } from 'src/app/services/carrito.service';
import { FirestoreService } from 'src/app/services/firestore.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit  {

  @ViewChild(IonInfiniteScroll) infiniteScroll : IonInfiniteScroll
  path = 'productos'
  productos : product[] =[]
  buscar : string = ''
  load : any
  nuevosSuscriber: Subscription;
  disablescroll: boolean = false;
  filters : any[]=[]
  promociones: boolean = false;
  todo: boolean = false;
  limit: any;
  category: string ;
  tienda : string = 'tienda'
  gender: any;
  order: any;


  constructor(private firestore : FirestoreService,    
    private auth : AuthService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    ) { }

  ngOnInit(): void {
    
    this.getFiltersValue()
    let e =  {name : 'todo'}
    this.filterCateogry(e)
    this.getProductFilter('date')
    this.ordenar();
    this.gender = this.route.snapshot.params.genero 
    }

    formFilter = this.fb.group({
      filter : [null],
    })

   resetSearch(e){
    if (e.detail.value == '') {
      this.search(e.detail.value)
      }
    }

   async search(q: string) { 
    this.productos = []
      this.firestore.search(q.toLocaleLowerCase(), this.route.snapshot.params.genero).subscribe(res =>{
        if (res.length > 0) {
          res.forEach(pedido =>{
            if (!this.productos.find( prod => prod['id'] === pedido['id'])) {
              this.productos.push(pedido)
            }
          })
        }
      })
    }
 

   getProductFilter(orden){
    console.log(orden);
    
    this.path = 'productos'
    let startAt = null
    if (this.productos.length) {
      switch (orden) {
        case 'date':
          startAt = this.productos[this.productos.length - 1].date
          break;
        case 'price':
          startAt = this.productos[this.productos.length - 1].price
          break;
        default:
          break;
      }
    }
    this.nuevosSuscriber = this.firestore.getCollectionProductos(this.path, 'gender', "==", this.route.snapshot.params.genero, 'style',this.route.snapshot.params.estilo, orden, startAt).subscribe((res)=>{
      if (res.length > 0) {
        res.forEach(pedido =>{
          if (!this.productos.find( prod => prod['id'] === pedido['id'])) {
             this.productos.push(pedido)
             
          }
        })
        console.log(this.productos);
        
      }else{
        console.log('disabled');
        
        this.disablescroll = true
      }
    })
  }

  cargarMAs(){
    let orderby:any={}
    if (!this.formFilter.get('filter').value) {
      orderby['value'] = 'date'
    }else{
      orderby = this.formFilter.get('filter').value
    }
    this.promociones ? this.getPromociones(orderby.value) : this.getProductFilter(orderby.value)
     this.infiniteScroll.complete()
  }

  getFiltersValue(){
    this.firestore.getCollection('orderby/').subscribe(res =>{
      this.filters = res
    })
  }
  filterCateogry(e){
    this.firestore.primero = true
    e.name == 'promociones' ? this.promociones = true :  this.promociones = false
    if (e.name == 'todo') {
      this.firestore.category = null 
      this.firestore.primero = true
      
    }
    this.category = e.name
    let orderby:any  ={}
     this.productos =   []
      if (this.formFilter.get('filter').value) {
        orderby = this.formFilter.get('filter').value
        if (orderby.order == "mayor") {
         this.firestore.mayor = true
        }
        
      }else{
        orderby.value = 'date'
      }
      if (orderby.order == 'menor') {
        this.firestore.primero = true
        this.firestore.order = 'asc'
      }else{
        this.firestore.primero = true
        this.firestore.order = 'desc'
      }
      this.firestore.category = e.name
      if (e.name == 'promociones') {
        this.getPromociones(orderby.value)
      }else{
        this.getProductFilter(orderby.value)
        console.log(orderby.value);
        
      }
      this.disablescroll = false
      console.log('firecategory', this.firestore.category);
      
  }

  ordenar(){
    this.formFilter.controls['filter'].valueChanges.subscribe(orderby =>{
      this.order = orderby.order
      if (orderby.order == 'menor') {
        this.firestore.primero = true
        this.firestore.order = 'asc'
      }else{ this.firestore.order = 'desc'}
      this.productos = []
      this.disablescroll = false
      this.promociones ? this.getPromociones(orderby.value) : this.getProductFilter(orderby.value)
      })
  }


  

  filterMayor(e){
    this.firestore.primero = true
    e.name == 'promociones' ? this.promociones = true :  this.promociones = false
   
    if (e.name == 'todo') {
      console.log('filtro todo');
      
      this.firestore.category = null 
      this.firestore.primero = true
    }
    this.category = e.name
    let orderby:any  ={}
     this.productos =   []
      if (this.formFilter.get('filter').value) {
        orderby = this.formFilter.get('filter').value
      }else{
        orderby.value = 'date'
      }
      if (orderby.order == 'menor') {
       
        
        this.firestore.primero = true
        this.firestore.order = 'desc'
      }
      this.firestore.category = e.name
      
      if (e.name == 'promociones') {
        if (orderby.order == 'menor') {
          this.firestore.primero = true
          this.firestore.order = 'desc'
        }
        this.getPromociones(orderby.value)
      }else{
        
        this.getProductFilter(orderby.value)
      }

      if (e.name == 'todo') {
        if (orderby.order == 'menor') {
          this.firestore.primero = true
          this.firestore.order = 'desc'
        }
        this.getProductFilter('date')
      }else{
        this.getProductFilter(orderby.value)
      }


       this.disablescroll = false
  }
  
  getPromociones(orden){
    this.disablescroll = false
    let startAt = null
    if (this.productos.length) {
      switch (orden) {
        case 'date':
          startAt = this.productos[this.productos.length - 1].date
          break;
        case 'price':
          startAt = this.productos[this.productos.length - 1].price
          break;
        default:
          break;
      }
    }
    this.firestore.getPromociones(this.path, 'gender', "==", this.route.snapshot.params.genero,  orden, startAt ).subscribe(res =>{
      if (res.length > 0) {
      res.forEach(producto => {
        if (!this.productos.find( prod => prod['id'] === producto['id'])){
           this.productos.push(producto)
        }
      })}else{
        this.disablescroll = true
      }
      
    })
  }



  getTodo(orden){
  
    this.getProductFilter('date')

 
  }
  
}
