import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FirestoreService } from 'src/app/services/firestore.service';
import { PreloadImgService } from 'src/app/services/preload-img.service';

@Component({
  selector: 'app-genero',
  templateUrl: './genero.component.html',
  styleUrls: ['./genero.component.scss'],
})
export class GeneroComponent implements OnInit {
  genders = []
  imgDefault = this.preloadImg.defaultImg
  constructor(
    private router : Router, 
    private firebase : FirestoreService,
    private preloadImg : PreloadImgService,
    ) { 
    
  }
  user ={ name : ''}
 ngOnInit() {
  // this.getGenders()
 }
 path = "genero"

//  getGenders(){
//   this.firebase.getCollection(this.path).subscribe(res =>{
//     console.log(res);
//     this.genders = res
    
//   })
//  }

  navigate(item){
    this.router.navigate([`/estilo/${item}`])
  }
}
