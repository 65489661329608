import { Component, Input, OnInit } from '@angular/core';
import { productPedido } from 'src/app/models/interfaces';
import { CarritoService } from 'src/app/services/carrito.service';

@Component({
  selector: 'app-item-pedidos',
  templateUrl: './item-pedidos.component.html',
  styleUrls: ['./item-pedidos.component.scss'],
})
export class ItemPedidosComponent implements OnInit {
  @Input() productoPedido : any
  constructor(public carrito: CarritoService) { }
  ngOnInit() {
    console.log(this.productoPedido);
    
  }

  addProduct(){
    this.carrito.addProduct(this.productoPedido.producto, 1)
  }

  removeProduct(){
    this.carrito.removeProduct(this.productoPedido.producto,1)
  }


}
