import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { PopoverController } from '@ionic/angular';
import { FirestoreService } from 'src/app/services/firestore.service';

@Component({
  selector: 'app-checkout-poppup',
  templateUrl: './checkout-poppup.component.html',
  styleUrls: ['./checkout-poppup.component.scss'],
})
export class CheckoutPoppupComponent implements OnInit {

  constructor(
    private popoverController: PopoverController, 
    private fb: FormBuilder,
    private firestore : FirestoreService
    ) { }
  @Input() 
  user:any ={};
  ngOnInit() {
    this.formData.patchValue({
      nombre : this.user.name,
      celular : this.user.phone
    })
  }

  formData = this.fb.group({
    nombre : [this.user.name, [Validators.required, Validators.minLength(6)]],
    celular : [this.user.phone, [Validators.required]]
  })

  aceptar(){
    let path = "users"
    this.firestore.updateDoc( {phone: this.formData.get('celular').value, name : this.formData.get('nombre').value},path,this.user.uid)
    this.popoverController.dismiss()
  }
}
