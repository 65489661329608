import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
declare var google : any
@Injectable({
  providedIn: 'root'
})
export class GoogleMapsService {

  apikey = environment.apikeyGoogle
  mapsLoad = false;
  constructor() { }
  id:'googleMaps'

  init(render : any, document : any):Promise<any> {
    return new Promise((resolve)=>{
   
      if (this.mapsLoad) {
        resolve(true)
        return
      }
      const script = render.createElement('script')
      script.id = 'googleMps'
      
      window['mapInit'] = ()=>{
        this.mapsLoad = true
        if (google) {
          console.log('google esta cargado');
        }else{
          console.log('google no esta cargado');
        }
        resolve(true)
        return
      }

      if (this.apikey) {
        script.src =`https://maps.googleapis.com/maps/api/js?key=${this.apikey}&libraries=places&language=es&callback=mapInit`
      }else{
        script.src =`https://maps.googleapis.com/maps/api/js?callback=mapInit`
      }

      render.appendChild(document.body, script)
      
    })
    

 
  }
}
