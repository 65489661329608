import { Component, OnInit, Output } from '@angular/core';
import { IonSlides } from '@ionic/angular';

@Component({
  selector: 'app-slide-promociones',
  templateUrl: './slide-promociones.component.html',
  styleUrls: ['./slide-promociones.component.scss'],
})
export class SlidePromocionesComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

  slideOpts = {
    initialSlide: 0,
    speed: 400
  };

  slidesDidLoad(slides: IonSlides) {
    slides.startAutoplay();
  }
}
